import React from 'react'
import { Route, Redirect} from "react-router-dom";
import useAuth from '../auth/useAuth'



export default function PrivateRouter ({component : Component,...rest}){

    document.getElementById('body').classList.remove('hold-transition');
    document.getElementById('body').classList.remove('login-page');




    const auth = useAuth()
    return(
            <Route {...rest}>
                
                    
                        {auth.token ? 
                        (<Component />)   
                : 
                (<Redirect to='/Login' />)
                
                } 
      
        </Route>

    )
}

