import React,{useEffect, useState} from 'react'
import $ from 'jquery'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'

const Find = () =>{
    const backend = process.env.REACT_APP_BACKEND_URL2
    const Token = sessionStorage.getItem('Token')
    const [DatosFicha,setDatosFichas]=useState({
        DatosPaciente: {
            Rut : '',
            Nombre : '',
            FechaNacimiento : '',
            Actividad : '',
            Telefono : '',
            Direccion : '',
            Comuna : '',
            Correo : '',
            idPaciente : '',
        },
        Anamnesis : {
            Motivo_Consulta : '',
            Antecedentes_Oculares : '',
            Antecedentes_Enf_Generales : '',
            Otros_Antecedentes : '',
            Profesional : ''
        },
        DatosMedicos : {
            RxUso_OD_Sph : '',
            RxUso_OD_Cyl:'',
            RxUso_OD_Axis:'',
            RxUso_OD_Add:'',
            RxUso_OI_Sph:'',
            RxUso_OI_Cyl:'',
            RxUso_OI_Axis:'',
            RxUso_OI_Add:'',
            AV_Lejos_OD_SC:'',
            AV_Lejos_OD_CC:'',
            AV_Lejos_OD_PH:'',
            AV_Lejos_OI_SC:'',
            AV_Lejos_OI_CC:'',
            AV_Lejos_OI_PH:'',
            AV_Cerca_OD_SC:'',
            AV_Cerca_OD_CC:'',
            AV_Cerca_OD_PH:'',
            AV_Cerca_OI_SC:'',
            AV_Cerca_OI_CC:'',
            AV_Cerca_OI_PH:'',
            Bio_OD:'',
            Bio_OI:'',
            Kero_OD_K1:'',
            Kero_OD_K2:'',
            Kero_OD_Eje:'',
            Kero_OI_K1:'',
            Kero_OI_K2:'',
            Kero_OI_Eje:'',
            Ref_OD_Sph:'',
            Ref_OD_Cyl:'',
            Ref_OD_Axis:'',
            Ref_DP_Lejos:'',
            Ref_OD_AVLejos:'',
            Ref_OD_AVCerca:'',
            Ref_OI_Sph:'',
            Ref_OI_Cyl:'',
            Ref_OI_Axis:'',
            Ref_DP_Cerca:'',
            Ref_OI_AVLejos:'',
            Ref_OI_AVCerca:'',
            Ref_Indicaciones:'',
            Ref_Observacion:''
        }
    })
    const [PacienteEncontrado,setPacienteEncontrado]=useState(false)
    const [FichasEncontradas,setFichasEncontradas]=useState(false)
    const [FichaSeleccionada,setFichaSeleccionada]=useState(false)
    const [ExistenFichas,setExistenFichas]=useState(false)
    const [DatosPaciente,setDatosPaciente]=useState([])
    const [ficha,setFicha]=useState([])
    const [listadoFolio,setListadoFolio]=useState([])
    const [paciente,setPaciente]=useState([])
 
    async function BuscarListadoFichasRut(){
        if($('#Rut').val()){
            let Rut = $('#Rut').val()
            await axios.post(backend+'/api/pacientes/find/exp',{'Rut':Rut},{headers : { authorization :'Bearere '+Token}})
            .then(res=>{
                if(res.data.code===1){
                    //Encontrado()
                    setPacienteEncontrado(true)
                    setDatosPaciente(res.data.datos)
                    $('#btnBuscar').addClass('d-none')
                    $('#Rut').attr('readOnly',true)
                    $('#btnLimpiar').removeClass('d-none')
                    toast.success('Paciente Encontrado',{
                        autoClose: 2000,
                        pauseOnHover: false,
                    });
                    BuscarFichas(res.data.datos.Rut,res.data.datos._id)
                }else{
                    //NoEncontrado()
                    $('#btnBuscar').removeClass('d-none')
                    $('#btnLimpiar').addClass('d-none')
                    $('#Rut').attr('readOnly',false)
                    setPacienteEncontrado(false)
                    setDatosPaciente([])
                    toast.success('Paciente No Fue Encontrado',{
                        autoClose: 2000,
                        pauseOnHover: false,
                    });
                }
                
            })
            .catch((error)=>{
                console.log('Error => '+error)
            })
        }
    }

    async function BuscarFichas(Rut,_id){
        console.log('Rut '+Rut)
        await axios.post(backend+'/api/fichas/find/exp/rut',{'Rut':Rut},{headers : { authorization :'Bearere '+Token}})
                .then(res=>{
                    console.log(res.data)
                    if(res.data.code===1){
                        setFichasEncontradas(true)
                        setExistenFichas(true)
                        cargarTabla1(_id)
                    }else{
                        setFichasEncontradas(false)
                        setExistenFichas(false)
                        toast.error('No Existen Fichas',{
                            autoClose: 2000,
                            pauseOnHover: false,
                        });
                    }
                })
                .catch((error)=>{
                    console.log('Error => '+error)
                })
    }
    async function cargarTabla1(idPaciente){
        
        
        console.log(idPaciente)
        var table = $('#tablaFichas').DataTable( {     
            ajax:{
           
                url : backend+'/api/fichas/find/exp/rut/listado',
                type : 'POST',
                data : {'idPaciente': idPaciente},
                dataSrc:'',
                headers : { authorization :'Bearere '+Token}
              },
            columns: [
                { data: '_id','visible':false },
                { data : 'Folio'},
                { data : 'Anamnesis.Motivo_Consulta'},
                { data : 'Anamnesis.Profesional'},
                {data : null,
                    render : function ( data, type, row, meta ) {
                      return   moment(data.CreateAt).format("DD-MM-YYYY hh:mm a");
                    }}
                 
            ],
            retrieve: true,
            autoWidth: false,
            processing: true,
            autoFill: true,
            colReorder: true,
            fixedHeader: true,
            lengthMenu: [
                [   10, 25, 50,-1],
                [ '10 Filas', '25 Filas', '50 Filas','Todo'  ]
            ],
            keys: true,
            responsive: true,
            dom: 'Bfrtip',
            buttons: [
                'copy', 'excel', 'pdf','csv','pageLength','print',{
                    text: '<i className="fas fa-sync"></i> Actualizar',
                    action: function ( e, dt, node, config ) {
                        
                        $('#tablaFichas').DataTable().ajax.reload( null, false );
                    }
                },
                
            ],
            
            
        });
        $('#tablaFichas tbody').on('click', 'tr', function () {
            var data = table.row(this).data();
            if(data){
                $('#Folio').val(data.Folio)
                let e =0
                buscarFolio(data.Folio)
                toast.success('Cargando Ficha ....',{
                    autoClose: 2000,
                    pauseOnHover: false,
                });
            
                //alert( 'You clicked on '+data.Folio+'\'s row' );
                
            }
            
        })

        
    }
    async function buscarFolio(e){
        //setDatosFichas([])
        if(e){
            let folio = e
            await axios.post(backend+'/api/fichas/find/exp/folio',{'Folio':folio},{headers : { authorization :'Bearere '+Token}})
            .then(res=>{
            if(res.data.code===1){
                //console.log(res.data.datos)
                setDatosFichas(res.data.datos)
                setFichaSeleccionada(true)
                $('#datosReceta').removeClass('d-none')
                //$('#tablaFichas').DataTable().destroy();
                $('#divTablaFichas').addClass('d-none')     
            }else{
                setFichaSeleccionada(false)
                
                  $('#datosReceta').addClass('d-none')
            }
        })
        .catch((error)=>{
            console.log(error)
        })
        }else{


        }

    }
    
    
    function VolverFicha(e){
        setExistenFichas(true)
        setFichaSeleccionada(false)
        $('#divTablaFichas').removeClass('d-none')
        cargarTabla1(DatosPaciente._id)
    }

    function LimpiarTodo(e){
        $('#Rut').attr('readOnly',false)
        $('#Rut').val('')
        $('#btnLimpiar').addClass('d-none')
        $('#btnBuscar').removeClass('d-none')
        setDatosPaciente([])
        setPacienteEncontrado(false)
        setFichasEncontradas(false)
        setFichaSeleccionada(false)
        setFicha([])
        $('#divTablaFichas').addClass('d-none')
    }
    
    

    return(
        <main className="content-wrapper">
            
            <ToastContainer />
            <div className="p-4">
                <div className="row">
                    <table className="table">
                        <tbody>
                            <tr>
                                <td><label className="text-info">Ingrese Rut :</label></td>
                                <td><input type="text" className="form-control buscaRut" id="Rut" name="Rut"/></td>
                                <td id="btnBuscar"><button type="button" className="btn btn-success" onClick={e=>BuscarListadoFichasRut(e)}><i className="fas fa-search"></i> Buscar Paciente</button></td>
                                <td id="btnLimpiar" className='d-none'><button type="button" className="btn btn-primary" onClick={e=>LimpiarTodo(1)} ><i className="fas fa-search"></i> Buscar Otro Paciente</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {PacienteEncontrado ? 
                    (<div>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td><label className="text-info">Rut</label></td>
                                    <td colSpan="2"><label className="text-info">Nombre</label></td>
                                    <td><label className="text-info">Direccion</label></td>    
                                </tr>
                                <tr>
                                    <td><input type="text" className="form-control" id="rut2" name="rut2" readOnly defaultValue={DatosPaciente.Rut} />
                                        <input type="hidden" name="idPaciente" id="idPaciente" defaultValue='0'/>
                                    </td>
                                    <td colSpan="2"><input type="text" className="form-control" id="nombre2" name="nombre2" readOnly defaultValue={DatosPaciente.Nombre}  /></td>
                                    <td><input type="text" className="form-control" id="direccion2" name="direccion2" readOnly defaultValue={DatosPaciente.Direccion} /></td>
                                </tr>
                                <tr>
                                    <td><label className="text-info">Comuna</label></td>
                                    <td><label className="text-info">Telefono</label></td>
                                    <td><label className="text-info">Actividad</label></td>
                                    <td><label className="text-info">Edad</label></td>
                                </tr>
                                <tr>
                                    <td><input type="text" className="form-control" id="comuna2" name="comuna2" readOnly defaultValue={DatosPaciente.Comuna} /></td>
                                    <td><input type="text" className="form-control" id="telefono2" name="telefono2" readOnly defaultValue={DatosPaciente.Telefono}  /></td>
                                    <td><input type="text" className="form-control" id="actividad2" name="actividad2" readOnly defaultValue={DatosPaciente.Actividad}  /></td>
                                    <td><input type="text" className="form-control" id="edad2" name="edad2" readOnly defaultValue={ficha.edad}  /></td>
                                </tr>
                            </tbody>
                        </table></div>) : ''}
                        {ExistenFichas ?
                        
                        (<div id="divTablaFichas"><table className="table" id="tablaFichas" width='100%'>
                        <thead>
                            <tr>
                                <th className="d-none">#</th>
                                <th><label className="text-info">Folio</label></th>
                                <th><label className="text-info">Motivo Consulta</label></th>
                                <th><label className="text-info">Profesional</label></th>
                                <th><label className="text-info">Fecha</label></th>
                            </tr>
                        </thead>
                    </table></div>) : ''
                        }
                        
                    

                    {FichaSeleccionada ? 
                    (<div className="row card">
                        <div className="card-header">
                            <h5 className='card-title'>Folio N° {DatosFicha.Folio}</h5>
                            <br/>
                            <button type="button" className='btn btn-success' onClick={e=>VolverFicha(e)}> Buscar Otra Ficha</button>
                        </div>
                        <div className="card-body">
                        
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td width="40%"><label className="text-info">Motivo Consulta</label></td>
                                    <td><textarea className="form-control" id="motivo_Consulta" readOnly name ="motivo_Consulta" rows="3" defaultValue={DatosFicha.Anamnesis.Motivo_Consulta}></textarea></td>
                                </tr>
                                <tr>
                                    <td><label className="text-info">Antecedentes Oculares</label></td>
                                    <td><textarea className="form-control" id="antecedentes_Oculares" readOnly name="antecedentes_Oculares" rows="3" defaultValue={DatosFicha.Anamnesis.Antecedentes_Oculares}></textarea></td>
                                </tr>
                                <tr>
                                    <td><label className="text-info">Antecedentes Enfermedades Generales</label></td>
                                    <td><textarea className="form-control" id="antecedentes_Enf_Generales" readOnly name="antecedentes_Enf_Generales" rows="3" defaultValue={DatosFicha.Anamnesis.Antecedentes_Enf_Generales}></textarea></td>
                                </tr>
                                <tr>
                                    <td><label className="text-info">Otros Antecedentes</label></td>
                                    <td><textarea className="form-control" id="otros_Antecedentes" readOnly name="otros_Antecedentes"  rows="3" defaultValue={DatosFicha.Anamnesis.otros_Antecedentes}></textarea></td>
                                </tr>
                                <tr>
                                    <td><label className="text-info">Profesional</label></td>
                                    <td><input type="text" className="form-control"  readOnly id="profesional" name="profesional" defaultValue={DatosFicha.Anamnesis.Profesional}/></td>
                                </tr> 
                            </tbody>
                        </table>
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td width="30%"><label className="text-info">Rx En Uso</label></td>
                                    <td className="text-center"><label className="text-info">Esf</label></td>
                                    <td className="text-center"><label className="text-info">Cyl</label></td>
                                    <td className="text-center"><label className="text-info">Eje</label></td>
                                    <td className="text-center"><label className="text-info">Add</label></td>
                                </tr>
                                <tr>
                                    <td><label className="text-info">OD</label></td>
                                    <td><input type="text" id="RxUso_OD_Sph" readOnly name="RxUso_OD_Sph" defaultValue={DatosFicha.DatosMedicos.RxUso_OD_Sph} className="form-control input-sm"/>  </td>
                                    <td><input type="text" id="RxUso_OD_Cyl" readOnly name="RxUso_OD_Cyl" defaultValue={DatosFicha.DatosMedicos.RxUso_OD_Cyl} className="form-control input-sm"/>  </td>
                                    <td><input type="text" id="RxUso_OD_Axis" readOnly name="RxUso_OD_Axis" defaultValue={DatosFicha.DatosMedicos.RxUso_OD_Axis} className="form-control input-sm"/>  </td>
                                    <td><input type="text" id="RxUso_OD_Add" readOnly name="RxUso_OD_Add" defaultValue={DatosFicha.DatosMedicos.RxUso_OD_Add} className="form-control input-sm"/>  </td>
                                </tr>
                                <tr>
                                    <td><label className="text-info">OI</label></td>
                                    <td><input type="text" id="RxUso_OI_Sph" readOnly name="RxUso_OI_Sph" defaultValue={DatosFicha.DatosMedicos.RxUso_OI_Sph} className="form-control input-sm"/>  </td>
                                    <td><input type="text" id="RxUso_OI_Cyl" readOnly name="RxUso_OI_Cyl" defaultValue={DatosFicha.DatosMedicos.RxUso_OI_Cyl} className="form-control input-sm"/>  </td>
                                    <td><input type="text" id="RxUso_OI_Axis" readOnly name="RxUso_OI_Axis" defaultValue={DatosFicha.DatosMedicos.RxUso_OI_Axis} className="form-control input-sm"/>  </td>
                                    <td><input type="text" id="RxUso_OI_Add" readOnly name="RxUso_OI_Add" defaultValue={DatosFicha.DatosMedicos.RxUso_OI_Add} className="form-control input-sm"/>  </td> 
                                </tr>
                            </tbody>
                        </table>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <td colSpan="4" className="text-center"><label className="text-info">AV Lejos</label></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center"><label className="text-info">VL</label></td>
                                    <td className="text-center"><label className="text-info">SC</label></td>
                                    <td className="text-center"><label className="text-info">CC</label></td>
                                    <td className="text-center"><label className="text-info">PH</label></td>
                                </tr>
                                <tr>
                                    <td><label className="text-info">OD</label></td>
                                    <td><input type="text" id="AV_Lejos_OD_SC" name="AV_Lejos_OD_SC" readOnly defaultValue={DatosFicha.DatosMedicos.AV_Lejos_OD_SC} className="form-control input-sm"/>  </td>
                                    <td><input type="text" id="AV_Lejos_OD_CC" name="AV_Lejos_OD_CC" readOnly defaultValue={DatosFicha.DatosMedicos.AV_Lejos_OD_CC} className="form-control input-sm"/></td>
                                    <td><input type="text" id="AV_Lejos_OD_PH" name="AV_Lejos_OD_PH" readOnly defaultValue={DatosFicha.DatosMedicos.AV_Lejos_OD_PH} className="form-control input-sm"/></td>
                                </tr>
                                <tr>
                                    <td><label className="text-info">OI</label></td>
                                    <td><input type="text" id="AV_Lejos_OI_SC" name="AV_Lejos_OI_SC" readOnly defaultValue={DatosFicha.DatosMedicos.AV_Lejos_OI_SC} className="form-control input-sm"/>  </td>
                                    <td><input type="text" id="AV_Lejos_OI_CC" name="AV_Lejos_OI_CC" readOnly defaultValue={DatosFicha.DatosMedicos.AV_Lejos_OI_CC} className="form-control input-sm"/></td>
                                    <td><input type="text" id="AV_Lejos_OI_PH" name="AV_Lejos_OI_PH" readOnly defaultValue={DatosFicha.DatosMedicos.AV_Lejos_OI_PH} className="form-control input-sm"/></td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <td colSpan="4" className="text-center"><label className="text-info">AV Cerca</label></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center"><label className="text-info">VP</label></td>
                                    <td className="text-center"><label className="text-info">SC</label></td>
                                    <td className="text-center"><label className="text-info">CC</label></td>
                                    <td className="text-center"><label className="text-info">PH</label></td>
                                </tr>
                                <tr>
                                    <td><label className="text-info">OD</label></td>
                                    <td><input type="text" readOnly id="AV_Cerca_OD_SC" name="AV_Cerca_OD_SC" defaultValue={DatosFicha.DatosMedicos.AV_Cerca_OD_SC} className="form-control input-sm"/>  </td>
                                    <td><input type="text" readOnly id="AV_Cerca_OD_CC" name="AV_Cerca_OD_CC" defaultValue={DatosFicha.DatosMedicos.AV_Cerca_OD_CC} className="form-control input-sm"/></td>
                                    <td><input type="text" readOnly id="AV_Cerca_OD_PH" name="AV_Cerca_OD_PH" defaultValue={DatosFicha.DatosMedicos.AV_Cerca_OD_PH} className="form-control input-sm"/></td>
                                </tr>
                                <tr>
                                    <td><label className="text-info">OI</label></td>
                                    <td><input type="text" readOnly id="AV_Cerca_OI_SC" name="AV_Cerca_OI_SC" defaultValue={DatosFicha.DatosMedicos.AV_Cerca_OI_SC} className="form-control input-sm"/>  </td>
                                    <td><input type="text" readOnly id="AV_Cerca_OI_CC" name="AV_Cerca_OI_CC" defaultValue={DatosFicha.DatosMedicos.AV_Cerca_OI_CC} className="form-control input-sm"/></td>
                                    <td><input type="text" readOnly id="AV_Cerca_OI_PH" name="AV_Cerca_OI_PH" defaultValue={DatosFicha.DatosMedicos.AV_Cerca_OI_PH} className="form-control input-sm"/></td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td colSpan="2" className="text-center"><label className="text-info">Biomicroscopia</label></td>
                                </tr>
                                <tr>
                                    <td><label className="text-info">OD</label></td>
                                    <td><input type="text" id="Bio_OD" name="Bio_OD" readOnly defaultValue={DatosFicha.DatosMedicos.Bio_OD} className="form-control input-sm"/>  </td>
                                </tr>
                                <tr>
                                    <td><label className="text-info">OI</label></td>
                                    <td><input type="text" id="Bio_OI" name="Bio_OI" readOnly defaultValue={DatosFicha.DatosMedicos.Bio_OI} className="form-control input-sm"/></td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th colSpan="4" className="text-center"><label className="text-info">Keratometria</label></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td width="10%"><label className="text-info">OD</label></td>
                                    <td><input type="text" id="Kero_OD_K1" name="Kero_OD_K1" readOnly defaultValue={DatosFicha.DatosMedicos.Kero_OD_K1} className="form-control input-sm"/></td>
                                    <td><input type="text" id="Kero_OD_K2" name="Kero_OD_K2" readOnly defaultValue={DatosFicha.DatosMedicos.Kero_OD_K2} className="form-control input-sm"/></td>
                                    <td><input type="text" id="Kero_OD_Eje" name="Kero_OD_Eje" readOnly defaultValue={DatosFicha.DatosMedicos.Kero_OD_Eje} className="form-control input-sm"/></td>
                                </tr>
                                <tr>
                                    <td width="10%"><label className="text-info">OI</label></td>
                                    <td><input type="text" id="Kero_OI_K1" name="Kero_OI_K1" readOnly defaultValue={DatosFicha.DatosMedicos.Kero_OI_K1} className="form-control input-sm"/></td>
                                    <td><input type="text" id="Kero_OI_K2" name="Kero_OI_K2" readOnly defaultValue={DatosFicha.DatosMedicos.Kero_OI_K2} className="form-control input-sm"/></td>
                                    <td><input type="text" id="Kero_OI_Eje" name="Kero_OI_Eje" readOnly defaultValue={DatosFicha.DatosMedicos.Kero_OI_Eje} className="form-control input-sm"/></td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th colSpan="7" className="text-center"><label className="text-info">Refraccion</label></th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th><label className="text-info">Esf</label></th>
                                    <th><label className="text-info">Cyl</label></th>
                                    <th><label className="text-info">Eje</label></th>
                                    <th><label className="text-info">Dp</label></th>
                                    <th><label className="text-info">AV Lejos</label></th>
                                    <th><label className="text-info">AV Cerca</label></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td width="10%"><label className="text-info">OD</label></td>
                                    <td><input type="text" id="Ref_OD_Sph" name="Ref_OD_Sph" readOnly defaultValue={DatosFicha.DatosMedicos.Ref_OD_Sph}className="form-control input-sm"/></td>
                                    <td><input type="text" id="Ref_OD_Cyl" name="Ref_OD_Cyl" readOnly defaultValue={DatosFicha.DatosMedicos.Ref_OD_Cyl}className="form-control input-sm"/></td>
                                    <td><input type="text" id="Ref_OD_Axis" name="Ref_OD_Axis" readOnly defaultValue={DatosFicha.DatosMedicos.Ref_OD_Axis}className="form-control input-sm"/></td>
                                    <td><input type="text" id="Ref_DP_Lejos" name="Ref_DP_Lejos" readOnly defaultValue={DatosFicha.DatosMedicos.Ref_DP_Lejos} placeholder="Lejos" className="form-control input-sm"/></td>
                                    <td><input type="text" id="Ref_OD_AVLejos" name="Ref_OD_AVLejos" readOnly defaultValue={DatosFicha.DatosMedicos.Ref_OD_AVLejos} className="form-control input-sm"/></td>
                                    <td><input type="text" id="Ref_OD_AVCerca" name="Ref_OD_AVCerca" readOnly defaultValue={DatosFicha.DatosMedicos.Ref_OD_AVCerca} className="form-control input-sm"/></td>
                                </tr>
                                <tr>
                                    <td width="10%"><label className="text-info">OI</label></td>
                                    <td><input type="text" id="Ref_OI_Sph" name="Ref_OI_Sph" readOnly defaultValue={DatosFicha.DatosMedicos.Ref_OI_Sph}className="form-control input-sm"/></td>
                                    <td><input type="text" id="Ref_OI_Cyl" name="Ref_OI_Cyl" readOnly defaultValue={DatosFicha.DatosMedicos.Ref_OI_Cyl}className="form-control input-sm"/></td>
                                    <td><input type="text" id="Ref_OI_Axis" name="Ref_OI_Axis" readOnly defaultValue={DatosFicha.DatosMedicos.Ref_OI_Axis}className="form-control input-sm"/></td>
                                    <td><input type="text" id="Ref_DP_Cerca" name="Ref_DP_Cerca" readOnly defaultValue={DatosFicha.DatosMedicos.Ref_DP_Cerca}placeholder="Cerca" className="form-control input-sm"/></td>
                                    <td><input type="text" id="Ref_OI_AVLejos" name="Ref_OI_AVLejos" readOnly defaultValue={DatosFicha.DatosMedicos.Ref_OI_AVLejos} className="form-control input-sm"/></td>
                                    <td><input type="text" id="Ref_OI_AVCerca" name="Ref_OI_AVCerca" readOnly defaultValue={DatosFicha.DatosMedicos.Ref_OI_AVCerca} className="form-control input-sm"/></td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td><label className="text-info">Indicaciones</label></td>
                                    <td><textarea className="form-control" id="Ref_Indicaciones" name="Ref_Indicaciones" readOnly defaultValue={DatosFicha.DatosMedicos.Ref_Indicaciones} rows="3"></textarea></td>
                                </tr>
                                <tr>
                                    <td><label className="text-info">Observacion</label></td>
                                    <td><textarea className="form-control" id="Ref_Observacion" name="Ref_Observacion" readOnly defaultValue={DatosFicha.DatosMedicos.Ref_Observacion} rows="3"></textarea></td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    )
                    : ''
                    
                
                
                
                }
            </div>
        </main>
    )
}

export default Find