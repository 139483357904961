import React,{useState,useEffect} from 'react'
import 'bootstrap'

import './Navbar.css'
const backend = process.env.REACT_APP_BACKEND_URL

const Navbar =(props)=>{
    const [DatosUsuario,setDatosUsuario]=useState(JSON.parse(sessionStorage.getItem('User')))
    console.log(DatosUsuario)
    const [Foto]=useState("/img/"+DatosUsuario.Img)
    const NombreLocal = sessionStorage.getItem('NombreLocal')
    return(
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link" data-widget="pushmenu" href="#Push" role="button"><i className="fas fa-bars"></i></a>
                </li>
            </ul>
            <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                    <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#Sucursal" role="button">
                        <span>Sucursal : {NombreLocal}</span>
                    </a>
                </li>
                </ul>
                <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown user user-menu" >
                    <a className="nav-link" data-widget="control-sidebar" data-toggle="dropdown" data-slide="true" href="#Foto" role="button">
                    <img src={Foto} className="user-image" alt="UserImage"/>
                        <span className="hidden-xs" id="userLogin">{DatosUsuario.Username}</span>
                    </a>
                    <ul className="dropdown-menu">
                        <li className="user-header">
                            <img src={Foto} className="img-circle" alt="UserImage"/>
                            <p>
                                {props.Username}
                                <small>{DatosUsuario.Rol}</small>
                            </p>
                        </li>
                        <li className="user-body">
                            <div className="row">
                                <div className="col-xs-4 text-center">
                                    <a href="#Ventas">Ventas</a>
                                </div>
                            </div>
                        </li>
                        <li className="user-footer">
                            <div className="pull-left">
                                <a href="#Profile" className="btn btn-default btn-flat">Profile</a>
                            </div>
                            <div className="pull-right">
                                <a href="#SignOut" className="btn btn-default btn-flat">Sign out</a>
                            </div>
                        </li>
                    </ul>
                </li>
                
                
            
      <li className="nav-item">
        <a className="nav-link" data-widget="fullscreen" href="#FullScreen" role="button">
          <i className="fas fa-expand-arrows-alt"></i>
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#Expand" role="button">
          <i className="fas fa-th-large"></i>
        </a>
      </li>
    </ul>
  </nav>
    )
}

export default Navbar