import React from 'react'
import $ from 'jquery'
import { 
    NavLink
  } from "react-router-dom";




const Options = (props) =>{

    function AddActivelvl2(e){
        $('.nav-link').removeClass('active')
        $('#'+props.Menu).addClass('active')
        if(props.SubMenu){
            $('#'+props.SubMenu).addClass('active')
        }
    }


    return(
        <li className="nav-item">
        <NavLink to={props.url} className="nav-link" onClick={e=>AddActivelvl2(e)}>
            <i className={props.icon}></i>
            <p>{props.nombre}</p>
        </NavLink>
        </li>
    )
}

export default Options