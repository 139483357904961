import {useState,useEffect} from 'react'
import './Insert.css'
import $ from 'jquery'
import 'jquery-ui/ui/widgets/autocomplete'
import 'jquery-ui/themes/base/all.css'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import axios  from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const customId = "custom-id-yes";
const backend = process.env.REACT_APP_BACKEND_URL2
const Local = sessionStorage.getItem('Local')

const Insert = () =>{
    const [cantItems,setCantItems]=useState(1)
    async function enviarDatos(e){
        e.preventDefault()
        let datosBusqueda = 0
        let responseMovement=''
        let SuccessEvent = []
        let ErrorEvent = []
        SuccessEvent.push('<ul class="list-group"><li class="list-group-item active" aria-current="true">Eventos Ocurridos</li>')
        ErrorEvent.push('<ul class="list-group"><li class="list-group-item list-group-item-dange" aria-current="true">Eventos Con Error</li>')
        
        let items = $('#items').val()
        for(let x=1;x<=items;x++){
            if($("#Modelo"+x).val()){
                datosBusqueda = {
                    modelo : $('#Modelo'+x).val(),
                    cantidad : $('#Cantidad'+x).val(),
                    precio : $('#Precio'+x).val()
                }
                let response = await BuscarProducto(datosBusqueda)
                //console.log('Buscando Producto ....')
                //console.log(response)
                if(response!==null){
                    let ExisteProd = await ExisteProductoEnSucursal(response,datosBusqueda.cantidad)
                    //console.log(ExisteProd)
                    if(ExisteProd.exist){
                        //console.log('Existe')
                        let datosAActualizar = {
                            idProducto : ExisteProd.idProducto,
                            Producto : ExisteProd.Producto,
                            Cantidad : ExisteProd.Cantidad
                        }
                        responseMovement = await ActualizarInventario(datosAActualizar)
                    }else{
                        //console.log('Crear')
                        let datosAInsertar={
                            idProducto : response._id,
                            Producto : response.Nombre,
                            Cantidad : ExisteProd.Cantidad,
                            Precio : datosBusqueda.precio
                        }
                        responseMovement = await IngresarAInventario(datosAInsertar)
                    }
                    SuccessEvent.push(responseMovement)
                    //console.log(responseMovement)

 


                }else{
                    ErrorEvent.push(`<li class="list-group-item">No Es Posible Ingresar El Producto `+datosBusqueda.modelo+` - No Existe Este Producto En el Listado de Productos Disponibles</li>`)
                    
                }
            }
        }
        console.log('Eventos Error')
        console.log(ErrorEvent)
        console.log('<br/>')
        console.log('Eventos Success')
        console.log(SuccessEvent)
    }

    async function ExisteProductoEnSucursal(e,cantidad){
        let resultado = null
        await axios.post(backend+'/api/inventarioSucursal/find/producto/sucursal/exp',{'Sucursal':Local,'Producto':e})
        .then(res=>{
            console.log(res.data)
            if(res.data.code===1){
                resultado={
                    idProducto : e._id,
                    exist : false,
                    Producto : e.Nombre,
                    Cantidad : cantidad,
                    Precio : e.PrecioVenta
                }
            }else if(res.data.code===2){
                //console.log('Actualizador')
                let cantidadExist=parseInt(res.data.datos.Cantidad)+parseInt(cantidad)
                resultado={
                    exist : true,
                    idProducto : res.data.datos._id,
                    idProductoInventario : res.data.datos._id,
                    Producto : e.Nombre,
                    Cantidad : cantidadExist,
                    CantidadAnterior : res.data.datos.Cantidad
                }
            }else{
                resultado = null
            }
            
        })
        .catch((error)=>{
            console.log('Error => '+error)
        })
        return resultado
    }

    async function BuscarProducto(datosBusqueda){
        let resultado = null
        let code=''
        await axios.post(backend+'/api/productos/find/exp',{'Producto':datosBusqueda})
                .then(res=>{
                    code=res.data.code
                    if(code===1){
                        resultado=res.data.datos
                    }else{
                        resultado=null
                    }
                    //console.log(res.data)
                })
                .catch((error)=>{
                    console.log('Error => '+error)
                })
        return resultado
    }
    async function IngresarAInventario(e){
        let response = null
        //console.log(e)
        await axios.post(backend+'/api/inventarioSucursal/insert/new',{'Sucursal':Local,'Producto':e})
        .then(res=>{
           if(res.data.code ===1){
                response=res.data
           }else{
                response=null
           }
            
        })
        .catch((error)=>{
            console.log('Error => '+error)
        })
        return response

    }
    async function ActualizarInventario(e){
        let response = null
        await axios.post(backend+'/api/inventarioSucursal/update/exp',{'Sucursal':Local,'Producto':e})
        .then(res=>{
            if(res.data.code ===1){
                response=res.data
            }else{
                response=null
            }
            })
            .catch((error)=>{
                console.log('Error => '+error)
            })
        return response
    }
    function clonadora(e){
        let currentItem = cantItems
        currentItem++
        setCantItems(currentItem)
        $("#tabla tbody tr:eq(0)").clone().removeClass('fila-base').appendTo("#tabla tbody").attr('id',currentItem);
        $("#tabla tbody tr:last td input").attr('id','Modelo'+currentItem).attr('placeholder','Modelo '+currentItem).attr('name','Modelo'+currentItem).attr('required','required');
        $("#tabla tbody tr:last td:eq(1) input").attr('id','Precio'+currentItem).attr('placeholder','Precio '+currentItem).attr('name','Precio'+currentItem).attr('required','required');
        $("#tabla tbody tr:last td:eq(2) input").attr('id','Cantidad'+currentItem).attr('placeholder','Cantidad '+currentItem).attr('name','Cantidad'+currentItem).attr('required','required');   
    }
    $(document).on("click",".eliminar",function(){
        $('#items').val(cantItems);
        var parent = $(this).parents().get(0);
        $(parent).remove();
    });

    function limpiar(){
        const cantidad = cantItems
        for(let x=2;x<=cantItems;x++){
            $('#'+x).remove()
        }
        $('#Modelo1').val('')
        $('#Cantidad1').val(1)
        setCantItems(1)
        //window.location.reload();
    }
    async function Autocompletar(e){
        $(document).on('focus','.auto2',function(){
            //console.log("a")
              let type = $(this).data('type');
             $(this).autocomplete({
               minLength: 1,
               source:function(type,response){
                 let buscar = type.term;
                 //let buscar = $(this).val()
                 buscar = buscar.toUpperCase();
                 //console.log(buscar);
                 $.ajax({
                   url: backend+"/api/productos/find/exp/"+buscar,
                   dataType:"json",
                   success:function(data){
                     response($.map(data, function (item) {
                       //console.log(data)
                       return {
                         label: item.Nombre+" - Precio Venta : "+item.PrecioVenta,
                         value: item.Nombre
                       }
                     }))
                   }
                 })
               }
             });
           });

        $(document).on('blur','.auto2',async function(){
            let id = $(this).attr('id')
            let num = id.substring(id.length-1)
            let texto = $(this).val()
            let datosBusqueda={
                modelo : texto,
                cantidad : 0
            }
            let resultado = await BuscarProducto(datosBusqueda)
            if(resultado!==null){
                $('#Precio'+num).val(resultado.PrecioVenta)
                //console.log('Existe '+resultado.PrecioVenta)
            }else{
                $('#Precio'+num).val(1)
                toast.error('Producto No Existe Favor Comprobar ....',{
                    autoClose: 2000,
                    toastId: customId,
                    pauseOnHover: false,
                    onClose: function(e){
                        $('#Producto'+num).val('')
                    } 
                });
                //console.log('No existe')
            }

            //console.log(num)
            //console.log(resultado)
        })
    }
    useEffect(() => {
        
        Autocompletar(1)
    },[])
    return(
        <main className="content-wrapper">
            <div className="p-4">
            <ToastContainer />
                <form onSubmit={enviarDatos}>
                    <div className="container">
                        <input type="hidden" id="items" name="items" value={cantItems} />
                        
                    </div>
                    <table className="table table-hover" width="100%" id="tabla">
                        <thead>
                            <tr>
                                <td width="65%"><label className="text-info">Modelo</label></td>
                                <td width="15%"><label className="text-info">Precio</label></td>
                                <td width="10%"><label className="text-info">Cantidad</label></td>
                                <td width="10%"></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="fila-base">
                                <td width="65%"><input type="text" className="form-control auto2"  placeholder="Modelo " /></td>
                                <td width="15%"><input type="number" className="form-control" min="0" defaultValue="0"  /></td>
                                <td width="10%"><input type="number" className="form-control" min="1" defaultValue="1"  /></td>
                                <td className="eliminar btn btn-danger btn-block" width="10%"><i className="fa fa-times" aria-hidden="true"/></td>
                            </tr>
                            <tr> 
                                <td width="65%"><input type="text" className="form-control  auto2" id="Modelo1" name="Modelo1" required  placeholder="Modelo1"/></td>
                                <td width="15%"><input type="number" className="form-control" min="0" defaultValue="0" id="Precio1" name="Precio1" /></td>
                                <td width="10%"><input type="number" className="form-control" min="1" defaultValue="1" id="Cantidad1" name="Cantidad1" /></td>
                                <td width="10%"></td>
                            </tr>
                        </tbody>
                    </table>
                    <div id="botones">
                        <button type="button" className="btn-flotante2" id="agregar2" onClick={e=>clonadora(e)}><i className="fas fa-plus"></i> Agregar Fila</button>
                        <button type="submit" className="btn-flotante"><i className="fas fa-archive"></i> Ingresar a Inventario</button>
                    </div>
                </form>
            </div>
        </main>
    )
}

export default Insert