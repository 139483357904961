import React,{useState,useEffect} from 'react';
import axios from 'axios'
import { Bar } from 'react-chartjs-2'
import useAuth from '../../auth/useAuth'
import './Dashboard.css'
//import '../../Script/Aside'

const backend = process.env.REACT_APP_BACKEND_URL2
const Local = sessionStorage.getItem('Local')
const fecha = new Date().toISOString().slice(0, 10);
//console.log(fecha)
const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };


const Dashboard = (props) =>{
    const auth = useAuth()
    const user = JSON.parse(sessionStorage.getItem('User'))
    const Rol = user.Rol
    const Token = sessionStorage.getItem('Token')
    const [cantidadEventosLocal,setCantidadEventosLocal]=useState(0)
    const [DatosGrafico,setDatosGrafico]=useState({
    labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    datasets: [
      {
        label: 'Ventas',
        data: [0, 0, 0, 0, 0, 0,0,0,0,0,0,0],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
        ],
        borderWidth: 1,
      },
    ],
  })
  const [Ventas,setVentas]=useState([])
  useEffect(() => {
      obtenerAtencionesHoy(Local,fecha)
      //console.log(primerDia)
      obtenerAnnos(1,Rol)
      
  },[])

  async function obtenerAtencionesHoy(e,fecha){
    await axios.post(backend+'/api/citaciones/sucursales/hoy',{'Sucursal':e,'Fecha':fecha},{headers : { authorization :'Bearere '+Token}})
    .then(res=>{
      if(res.data.code ===1){
        setCantidadEventosLocal(res.data.cantidad)
      }else{
 
      }
    })
    .catch((error)=>{
      console.log('Error => '+error)
    })

  }
  async function obtenerAnnos(e,Rol){
    //console.log(Rol)
    let datosInicial 
    await axios.post(backend+'/api/ventas/sucursal/anno',{},{headers : { authorization :'Bearere '+Token}})
    .then(res=>{
      //console.log(res.data.datos)
      setVentas(res.data.datos)
      if(e===1){
        datosInicial=res.data.datos[0]
        //console.log(datosInicial)
      }else{
        let aux=res.data.datos
        datosInicial=aux.filter(items=>items.Anno==e)
        datosInicial=datosInicial[0]
        //console.log(datosInicial)
      }
      //console.log(datosInicial)
      let Ventas=separadorVentas(datosInicial,Rol)
      setDatosGrafico({labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      datasets: [
        {
          label: 'Ventas',
          data: Ventas,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1,
        },
      ],
    })
    })
    .catch((error)=>{
      console.log('Error =>'+error)
    })
  }

  function separadorVentas(datos,Rol){
    //console.log('Datos en separador')
    //console.log(datos)
    let Anno = datos.Anno
    let Mes=''
    let Ventas = datos.Ventas
    let Arreglo=[]
    let aux=''
    let Sumador=0
    //console.log(Anno)
    for(let x=1;x<=12;x++){
      if(x<=9){
        Mes='0'+x
        //console.log('Mes : '+Mes)
        if(Rol==='Administrador' || Rol==='Desarrollador'){
          aux=Ventas.filter(items=>items.CreateAt.split('-')[1]==Mes)
        }else{
          aux=Ventas.filter(items=>items.CreateAt.split('-')[1]==Mes && items.Sucursal==sessionStorage.getItem('Local'))
        }
        
        if(aux.length>=1){
          Sumador=0
          for(let a=0;a<=aux.length-1;a++){
            Sumador=Sumador+parseInt(aux[a].MntTotal)
          }
          Arreglo.push(Sumador)
        }else{
          Arreglo.push(0)
        }
        //console.log(aux)
      }else{
        Mes=x
        //console.log('Mes : '+Mes)
        if(Rol==='Administrador' || Rol==='Desarrollador'){
          aux=Ventas.filter(items=>items.CreateAt.split('-')[1]==Mes)
        }else{
          aux=Ventas.filter(items=>items.CreateAt.split('-')[1]==Mes && items.Sucursal==sessionStorage.getItem('Local'))
        }
        if(aux.length>=1){
          Sumador=0
          for(let b=0;b<=aux.length-1;b++){
            Sumador=Sumador+parseInt(aux[b].MntTotal)
          }
          Arreglo.push(Sumador)
        }else{
          Arreglo.push(0)
        }
        
      }
    }
    //console.log(Arreglo)
    return Arreglo
  }
     

    
    return(
            <main className="content-wrapper">
                <div className="p-4">
                    <div className="row">
                        <div className="col-md-3 col-sm-6 col-12">
                            <div className="small-box bg-info">
                                <div className="inner">
                                    <h3>{cantidadEventosLocal}</h3>
                                    <p>Atenciones Para Hoy</p>
                                </div>
                                <div className="icon">
                                    <i className="fas fa-calendar-alt"></i>
                                </div>
                                <a href="#a" className="small-box-footer">
                                    Ir al Calendario <i className="fas fa-arrow-circle-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="card row">
                        <div className="form-group row">
                            <div className="col-2 text-center">
                                <label>A&ntilde;o</label>
                                <select className="form-control" id="AnnoSelect" onChange={e=>obtenerAnnos(e.target.value)} name="AnnoSelect">
                                    {Ventas.map(items=>
                                    <option key={items.Anno} value={items.Anno}>{items.Anno}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <Bar data={DatosGrafico} options={options} />
                    </div>
                </div>
            </main>
    )
}

export default Dashboard