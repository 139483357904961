import React,{useEffect,useState} from 'react'
import axios from 'axios'
import $ from 'jquery'
import moment from 'moment'
import { useHistory,Redirect } from 'react-router-dom'
import { Button, Modal, Alert } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';

import './New.css'
const New = () =>{
    const backend = process.env.REACT_APP_BACKEND_URL2
    const history = useHistory()
    const Local = sessionStorage.getItem('Local')
    const [idPaciente,setIdPaciente]=useState(0)
    const [VerOT,setVerOT]=useState(false)
    



    useEffect(()=>{
        functionesDeCarga()
        ObtenerListadoClientes()
    },[])

    async function ObtenerListadoClientes(){
        var table = $('#tablaClientes').DataTable( {     
            ajax:{
           
                url : backend+'/api/clientes/find/all',
                type : 'POST',
                data : '',
                dataSrc:''
              },
            columns: [
                { data: '_id','visible':false },
                { data : 'Rut'},
                { data : 'Nombre'},
                { data : 'Comuna'},
                
                 
            ],
            select: {
                style: 'single'
            },
            language: {
                url: 'http://cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json'
                },
            retrieve: true,
            autoWidth: false,
            processing: true,
            autoFill: true,
            colReorder: true,
            fixedHeader: true,
            lengthMenu: [
                [   10, 25, 50,-1],
                [ '10 Filas', '25 Filas', '50 Filas','Todo'  ]
            ],
            keys: true,
            responsive: true,
            dom: 'Bfrtip',
            buttons: [
                'copy', 'excel', 'pdf','csv','pageLength','print',{
                    text: '<i className="fas fa-sync"></i> Actualizar',
                    action: function ( e, dt, node, config ) {
                        
                        $('#tablaClientes').DataTable().ajax.reload( null, false );
                    }
                },
                { 
                    text: '<i class="fas fa-plus"></i> Nuevo Paciente',
                    action: function ( e, dt, node, config ) {
                        $('#ModalNuevoCliente').modal('show');
                    }
                }
                
            ],
            
            
        });
        $('#tablaClientes tbody').on('click', 'tr', function () {
            var data = table.row(this).data();
            if ( $(this).hasClass('selected') ) {
                //$(this).removeClass('selected');
            }
            else {
                table.$('tr.selected').removeClass('selected');
                $(this).addClass('selected');
            }
            if(data){
                setIdPaciente(data._id)
                
                
                $('#tablaOT').DataTable().destroy();
                $('#ot').removeClass('d-none')
                $('#NewID').val(data._id)
                //$('#Folio').val(data.Folio)
                //let e =0
                
                buscarFolio(data._id)
            
                //alert( 'You clicked on '+data.Folio+'\'s row' );
                //$('#NombreOculto').addClass('d-none')
            }
            
        })
    }
 
    const MostrarFolios = () =>setVerOT(true)
    const EsconderFolios = () =>setVerOT(false)

    async function buscarFolio(e){
        var table2 = $('#tablaOT').DataTable( {     
            ajax:{
           
                url : backend+'/api/atencionLC/particular/find/exp',
                type : 'POST',
                data : {'idPaciente':e},
                dataSrc:''
              },
            columns: [
                { data: '_id','visible':false },
                { data : 'OT'},
                {data : null,
                    render : function ( data, type, row, meta ) {
                      return   moment(data.CreateAt).format("DD-MM-YYYY hh:mm a");
                    }}
                
                 
            ],
            select: {
                style: 'single'
            },
            retrieve: true,
            autoWidth: false,
            processing: true,
            autoFill: true,
            colReorder: true,
            fixedHeader: true,
            lengthMenu: [
                [   10, 25, 50,-1],
                [ '10 Filas', '25 Filas', '50 Filas','Todo'  ]
            ],
            keys: true,
            responsive: true,
            dom: 'Bfrtip',
            buttons: [
                'copy', 'excel', 'pdf','csv','pageLength','print',{
                    text: '<i className="fas fa-sync"></i> Actualizar',
                    action: function ( e, dt, node, config ) {
                        
                        $('#tablaOT').DataTable().ajax.reload( null, false );
                    }
                },
                { 
                    text: '<i class="fas fa-plus"></i> Nueva OT',
                    action: function ( e, dt, node, config ) {
                        $('#ModalNuevaOT').modal('show');
                    }
                }
                
            ],
            
            
        });
        $('#tablaOT tbody').on('click', 'tr', function () {
            var data = table2.row(this).data();
            if ( $(this).hasClass('selected') ) {
                $(this).removeClass('selected');
            }
            else {
                table2.$('tr.selected').removeClass('selected');
                $(this).addClass('selected');
            }
            if(data){
                history.push('/opticaReflejos/Contactologia/Junaeb/VerAtencion',{id_atencion : data._id,id_cliente : idPaciente})
                //$('#ModalMovimiento').modal('show')
                //$('#idMovimiento').val(data._id)
                //alert( 'You clicked on '+data._id+'\'s row' );
                //$('#NombreOculto').addClass('d-none')
            }
            
        })
    }
    function functionesDeCarga(){
        $('.limitocoma').on('keypress',function(tecla){
            //console.log(tecla.charCode)
            if(tecla.charCode == 44){
                return false
            }
        })
    }
    


    return(
        <main className="content-wrapper">
            <div className="p-4">
                <table className="table" id="tablaClientes">
                    <thead>
                        <tr>
                            <th><label>#</label></th>
                            <th><label>Rut</label></th>
                            <th><label>Nombre</label></th>
                            <th><label>Comuna</label></th>
                        </tr>
                    </thead>
                </table>
                <div id="ot" className="d-none">
                    <table className="table" id="tablaOT">
                        <thead>
                            <tr>
                                <th><label>#</label></th>
                                <th><label>OT</label></th>
                                <th><label>Fecha</label></th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
            




        </main>
    )
}

export default New