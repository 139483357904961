import {Link,useHistory} from 'react-router-dom'
import {useState,useEffect} from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import useAuth from '../../auth/useAuth'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';



const Login = () =>{
    const [isAuthLoading, setAuthLoading] = useState(false);
    const [resultado,setResultado]=useState(null)
    
    const history = useHistory();
    const auth = useAuth();
    
    const [datos,setDatos]=useState({
        Rut : '',
        Password : ''
      })
    const backend = process.env.REACT_APP_BACKEND_URL2
   


    const Enviar = async(e) =>{
        console.log('Backend '+ backend)
        try{
            e.preventDefault()
            const result = await VerificarExistencia(1)

            if(result.data===null){
                //console.log('Error En Servidor Backend')
                setAuthLoading(false)
                setResultado(null)
                toast.error('Error En Servidor Backend ....',{
                    autoClose: 2000,
                    pauseOnFocusLoss : false
                });
 
                
            }
            if(result.data!==null && result.data.code===1){
                
                setAuthLoading(true)
                setResultado(result.data.datos)
                
                  sessionStorage.setItem('Token',result.data.Jwt)
                  sessionStorage.setItem('User',JSON.stringify(result.data.datos))
                  auth.login(result.data.Jwt,true,result.data.datos)
                  if(result.data.datos.idSucursal){
                      sessionStorage.setItem('Local',result.data.datos.idSucursal)
                      sessionStorage.setItem('NombreLocal',result.data.datos.Sucursal)
                      history.push('/Loading',{state : '/Dashboard'})
                  }else{
                    history.push('/Sucursal');
                  }
                  //
                
            }
            if(result.data!==null && result.data.code===0){
                console.log('Error en Usuario y Contraseña')
                setAuthLoading(false)
                setResultado(null)
                toast.error('Usuario y/o Contraseña Incorrectos ....',{
                    autoClose: 2000,
                    pauseOnFocusLoss : false

                });

                
            }
            
        }catch(error){
            //console.log('Backend'+backend)
            //console.log(error)
        }
        
    }

    

    async function VerificarExistencia(e){
        let result = {
            estado : false,
            data : null
        }
        await axios.post(backend+'/api/account/find',{'DatosCuenta':datos})
        .then(res=>{
            //console.log(res)
            result={
                estado : 'ok',
                data : res.data
            }
        })  
        .catch((error)=>{
            
           result={
               estado : error,
               data : null
           }
        })
        //console.log(result)
        return result
    }
    const handleCapture = (event) => {
        // console.log(event.target.name)
        // console.log(event.target.value)
        setDatos({
            ...datos,
            [event.target.name] : event.target.value
        })
    } 

    document.getElementById('root').classList = 'hold-transition login-page';
    return(
        <div className="login-box">
            <ToastContainer />
            <div className="card card-outline card-primary">
            
                <div className="card-header text-center">
                
                    <Link to="/" className="h1">
                    <img className="mb-4" src="/img/Simbolo.svg" alt="" width="80" height="80"/>
                        <b>Optica</b>
                        <span>Reflejos</span>
                    </Link>
                </div>
                <div className="card-body">
                    <p className="login-box-msg"></p>
                    <form onSubmit={Enviar}>
                        
                        <div className="mb-3">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Usuario" name="Rut" id="Rut" onChange={handleCapture}/>
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope" />
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className="mb-3">
                            <div className="input-group">
                                <input type="password" className="form-control" placeholder="Password" name="Password" id="Password" onChange={handleCapture}/>
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock" />
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <button className="btn btn-primary btn-block">
                                    <i className="fa-solid fa-right-to-bracket"></i> Ingresar
                                </button>
                            </div>
                        </div>
                    </form>
                    
                    <p className="mb-1">
                        <Link to="/forgot-password">
                            Contraseña Perdida
                        </Link>
                    </p>
                    <p className="mb-0">
                        <Link to="/register" className="text-center">
                            Registrar
                        </Link>
                    </p>
                </div>
            </div>
            
        </div>
    )
}

export default Login