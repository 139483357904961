
import './App.scss';
import 'admin-lte/dist/css/adminlte.min.css'
import MainRouter from './Router/MainRouter'
import AuthProvider from "./auth/AuthProvider";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <MainRouter />
      </AuthProvider>
    </div>
  );
}

export default App;
