import './LoadingState.scss'
import {useEffect,useState} from 'react'
import { useLocation,useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const customId = "custom-id-yes";

const Loading =()=>{
    const location = useLocation();
    const history = useHistory();
    const [newURL,setNewURL]=useState('')
    document.getElementById('root').classList.remove('register-page');
    document.getElementById('root').classList.remove('login-page');
    document.getElementById('root').classList.remove('hold-transition');
    
    

    useEffect(() => {
        if(location.state){
            toast.success('Ingresando ....',{
                autoClose: 3000,
                pauseOnFocusLoss : false,
                onClose: () => 
                history.push(location.state.state)
            });
        }else{

        }  
    })

    

    
    return(
        <div>
            <ToastContainer />
            <div className='loading'>
            <span>
                <img src='/img/Simbolo.svg' alt='Logo' />
            </span>
            <span>O</span>
            <span>P</span>
            <span>T</span>
            <span>I</span>
            <span>C</span>
            <span>A</span>
            <span></span>
            <span>R</span>
            <span>E</span>
            <span>F</span>
            <span>L</span>
            <span>E</span>
            <span>J</span>
            <span>O</span>
            <span>S</span>
        </div>

        </div>
    )
}

export default Loading