import {useState,useEffect} from 'react'
import $ from 'jquery'
import axios from 'axios'

import 'pdfmake'
import 'jszip'
import 'datatables.net-bs5/css/dataTables.bootstrap5.css'
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.css'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-buttons/js/dataTables.buttons.js'
import 'datatables.net-buttons/js/buttons.colVis'
import 'datatables.net-buttons/js/buttons.html5'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons-bs4/js/buttons.bootstrap4.min.js'







import {Modal} from 'react-bootstrap'

const Sucursal = sessionStorage.getItem('Local')
const backend = process.env.REACT_APP_BACKEND_URL2
const user = JSON.parse(sessionStorage.getItem('User'))
const Token = sessionStorage.getItem('Token')

$.DataTable = require('datatables.net')

const View = () =>{
    
    const [AbrirModal1,setAbrirModal1]=useState(false)
    const [Inventario,setInventario]=useState([])
    const [DatosModificar,setDatosModificar]=useState([])
    const AbroModal = ()=>setAbrirModal1(true)
    const CerrarModal = () => setAbrirModal1(false)
 
    useEffect(() =>{
        //CargarInventario(1)
    },[])
    useEffect(() => { 
        CargarDatatable(1)
    },[])

    async function CargarInventario(e){
        await axios.post(backend+'/api/sucursales/inventario/fill/all',{'Sucursal':Sucursal},{headers : { authorization :'Bearere '+Token}})
        .then(res=>{
            setInventario(res.data)
            console.log(res.data)
        })
        .catch(error=>{
            console.log('Error : ' + error)
        })

    }
  
    function CargarDatatable(e){
        try{
            const table = $('#TablaInventario').DataTable( {
  
                ajax:{     
                  url : backend+'/api/sucursales/inventario/fill/all',
                  type : 'POST',
                  data : {
                      'Sucursal':sessionStorage.getItem('Local')
                  },
                  headers : { authorization :'Bearere '+Token},
                  dataSrc:''
                }, 
              columns: [
                  { data: '_id' ,'visible': false},
                  { data: 'idProducto','visible':false },
                  { data: 'idProducto','visible': false},
                  { data: 'Producto' },
                  { data: 'Cantidad' },
                  { data: 'Precio',render: $.fn.dataTable.render.number( '.', ',', 0, '$' )  },
                  
                  
              ],
              language: {
                url: 'http://cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json'
                },
              retrieve: true,
              autoWidth: false,
              processing: true,
              autoFill: true,
              colReorder: true,
              fixedHeader: true,
              lengthMenu: [
                  [   10, 25, 50,-1],
                  [ '10 Filas', '25 Filas', '50 Filas','Todo'  ]
              ],
              keys: true,
              responsive: true,
              dom: 'Bfrtip',
              buttons: [
                  'copy', 'excel', 'pdf','csv','pageLength','print',{
                          text: '<i className="fas fa-sync"></i> Actualizar',
                          action: function ( e, dt, node, config ) {
                              $('#TablaInventario').DataTable().ajax.reload( null, false );
                          }
                      }
              ],
              
            }); 
            $('#TablaInventario tbody').on('click', 'tr', function () {
                var data = table.row(this).data();
                
                if(user.Rol ==='Desarrollador'){
                    console.log(data)
                    setDatosModificar(data)
                    console.log(DatosModificar)
                    AbroModal()
                }
                  //alert( 'You clicked on '+data._id+'\'s row' );
              } );

        }catch(error){
            console.log('Error try : ' + error)
        }
    }

    async function ModificarCantidad(e){

    }

    return(
        <main className="content-wrapper">
            <div className="p-4">
                <table className="table" id="TablaInventario">
                    <thead>
                        <tr>
                            <th><label className="text-info">ID</label></th>
                            <th><label className="text-info">ID Producto</label></th>
                            <th><label className="text-info">QR</label></th>
                            <th><label className="text-info">Producto</label></th>
                            <th><label className="text-info">Cantidad</label></th>
                            <th><label className="text-info">Precio</label></th>
                        </tr>
                    </thead>
                </table>
            </div>
            <Modal size="lg" show={AbrirModal1} onHide={CerrarModal} aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">Modo Administrador</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="form-horizontal" onSubmit={e=>ModificarCantidad(e)}>
                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="title" className="col-sm-4 control-label">ID Producto</label>
                                <div className="col-sm-12">
                                    <input type="text" readOnly={true} className="form-control" defaultValue={DatosModificar._id} name="ModId" id="ModId" placeholder="Id"/>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="title" className="col-sm-4 control-label">Producto</label>
                                <div className="col-sm-12">
                                    <input type="text" readOnly={true} name="ModNombre" defaultValue={DatosModificar.Producto} className="form-control" id="ModNombre" placeholder="Nombre"/>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="title" className="col-sm-4 control-label">Cantidad</label>
                                <div className="col-sm-12">
                                    <input type="number" name="ModCantidad" defaultValue={DatosModificar.Cantidad} className="form-control" id="ModCantidad" placeholder="Cantidad"/>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="title" className="col-sm-4 control-label">Precio</label>
                                <div className="col-sm-12">
                                    <input type="number" name="ModPrecio" className="form-control" id="ModPrecio" placeholder="Precio" defaultValue={DatosModificar.Precio}/>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </main>
    )
}

export default View