import {useState,useEffect} from 'react'
import axios from 'axios'
import $ from 'jquery' 
import {Modal} from 'react-bootstrap'

const backend = process.env.REACT_APP_BACKEND_URL2

const NewVenta = () =>{
    const [ListaVendedores,setListaVendedores] =useState([])
    const [DatosCliente,setDatosCliente] =useState([])
    const [ShowMostrarProducto,setShowMostrarProducto] =useState(false)

    const [ExistenCliente,setExisteCliente]=useState(false)

    const EsconderProductosModal = () => setShowMostrarProducto(false)
    const MostrarProductosModal = ()=> setShowMostrarProducto(true)

    async function RegistrarVenta(e){

    }
    async function ExisteRut(e){
        axios.post(backend+'/api/pacientes/find/exp',{'Rut':e})
        .then(res=>{
            const code = res.data.code
            //console.log(res.data)
            if(code===1){
                setExisteCliente(true)
                setDatosCliente(res.data.datos)
                //console.log("registrado")
                $('#RznSocRecep').val(res.data.datos.Nombre)
                $('#TelefonoPaciente').val(res.data.datos.Telefono)
                $('#DirRecep').val(res.data.datos.Direccion)
                $('#CmnaRecep').val(res.data.datos.Comuna)
                $('#ActividadPaciente').val(res.data.datos.Actividad)
                $('#CorreoPaciente').val(res.data.datos.Correo)
                $('#codigoCliente').val(res.data.datos._id)
            }else{
                setExisteCliente(false)
                $('#codigoCliente').val('')
                $('#RznSocRecep').val('')
                $('#TelefonoPaciente').val('')
                $('#DirRecep').val('')
                $('#CmnaRecep').val('')
                $('#ActividadPaciente').val('')
                $('#CorreoPaciente').val('')
            }
        })
        .catch((error)=>{
            console.log('Error => '+error)
        })
    }

    async function Vendedores(e){
        await axios.post(backend+'/api/listaVendedores/find/all')
        .then(res=>{
            if(res.data.code===1){
                setListaVendedores(res.data.datos)
            }else{
                setListaVendedores([])
            }    
        })
        .catch((error)=>{
            console.log('Error => '+error)
        })
    }
    async function MuestraBuscarProducto(e){

    }
    async function MuestraOrdenesDeTrabajo(e){

    }
    async function MuestraOrdenesDeTrabajoLC(e){

    }
    async function MuestraOrdenesDeTrabajoAyudaVisual(e){

    }

    useEffect(() => {
        Vendedores(1)
    },[])

    return(
        <section className="home-section2" id="contenidoPrincipal">
            <div className="home-content2">
                <i className='bx bx-menu' ></i>
                <span className="text">Optica Reflejos</span>
            </div>
            <div className='p-4'>
                <form onSubmit={e=>RegistrarVenta(e)}>
                    <div className="card card-success card-outline">
                        <div className="card-header">
                            <h3 className="card-title">Datos Cliente</h3>
                            <div className="card-tools">
                                <button type="button" className="btn btn-tool" data-card-widget="card-refresh" data-source="widgets.html" data-source-selector="#card-refresh-content" data-load-on-init="false">
                                    <i className="fas fa-sync-alt"></i>
                                </button>
                                <button type="button" className="btn btn-tool" data-card-widget="maximize">
                                    <i className="fas fa-expand"></i>
                                </button>
                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                    <i className="fas fa-minus"></i>
                                </button>
                                     {/*}<button type="button" className="btn btn-tool" data-card-widget="remove">
                                            <i className="fas fa-times"></i>
                                     </button>{*/}
                            </div>
                        </div>
                        <div class="card-body">
                            <table className="table table-sm">
                                <tbody>
                                    <tr>
                                        <td><label>Codigo Cliente</label></td>
                                        <td><input type="text" readOnly={true} className="form-control form-control-sm" id="codigoCliente" name="codigoCliente" /></td>
                                        <td><label>Fecha Documento</label></td>
                                        <td><input type="date" className="form-control form-control-sm"  id="FchEmis" name="FchEmis" /></td>
                                    </tr>
                                    <tr>
                                        <td><label>Rut</label></td>
                                        <td><input type="text" required className="form-control form-control-sm buscaRut" onBlur={e=>ExisteRut(e.target.value)} id="RUTRecep" name="RUTRecep" /></td>
                                        <td><label>Indica Servicio</label></td>
                                        <td>
                                            <select readOnly={true} className="form-control form-control-sm" id="IndServicio" name="IndServicio">
                                            {/*}<option value="1">1 - Boletas de servicios periódicos</option>
                                            <option value="2">2 - Boletas de servicios periódicos domiciliarios</option>{*/}
                                            <option value="3" selected >3 -Boletas de venta y servicios</option>{/*}
                                            <option value="4">4 - Boleta de Espectáculo emitida por cuenta de Terceros</option>{*/}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><label>Nombre</label></td>
                                        <td><input type="text" required className="form-control form-control-sm" id="RznSocRecep" name="RznSocRecep" /></td>
                                        <td><label>Vendedor</label></td>
                                        <td><select className="form-control form-control-sm" id="Vendedor" name="Vendedor">
                                            {ListaVendedores.map(items=>
                                            <option key={items._id} value={items._id}>{items.Nombre}</option>
                                            )}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><label>Direccion</label></td>
                                        <td><input type="text" required className="form-control form-control-sm" id="DirRecep" name="DirRecep" /></td>
                                        <td><label>Telefono</label></td>
                                        <td><input type="text" className="form-control form-control-sm" id="TelefonoPaciente" name="TelefonoPaciente" /></td>
                                    </tr>
                                    <tr>
                                        <td><label>Comuna</label></td>
                                        <td><input type="text" className="form-control form-control-sm" id="CmnaRecep" name="CmnaRecep" /></td>
                                        <td><label>Correo</label></td>
                                        <td><input type="text" className="form-control form-control-sm" id="CorreoPaciente" name="CorreoPaciente" /></td>
                                    </tr>
                                    <tr>
                                        <td><label>Cuidad</label></td>
                                        <td><input type="text" className="form-control form-control-sm" id="CiudadRecep" name="CiudadRecep" /></td>
                                        <td><label>Actividad</label></td>
                                        <td><input type="text" className="form-control form-control-sm" id="ActividadPaciente" name="ActividadPaciente" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card card-success card-outline">
                        <div className="card-header">
                            <h3 className="card-title">Detalle Venta</h3>
                            <div className="card-tools">
                                <button type="button" className="btn btn-tool" data-card-widget="card-refresh" data-source="widgets.html" data-source-selector="#card-refresh-content" data-load-on-init="false">
                                    <i className="fas fa-sync-alt"></i>
                                </button>
                                <button type="button" className="btn btn-tool" data-card-widget="maximize">
                                    <i className="fas fa-expand"></i>
                                </button>
                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                    <i className="fas fa-minus"></i>
                                </button>
                                {/*}<button type="button" className="btn btn-tool" data-card-widget="remove">
                                <i className="fas fa-times"></i>
                                </button>{*/}
                            </div>
                        </div>
                        <div class="card-body">
                            <table className="table-sm">
                                <tbody>
                                    <tr>
                                        <td><button type="button" className="btn btn-success btn-sm" onClick={e=>MuestraBuscarProducto(e)}><i className="fas fa-search"></i> Buscar Producto</button></td>
                                        <td><button type="button" className="btn btn-success btn-sm" onClick={e=>MuestraOrdenesDeTrabajo(e)}><i className="fas fa-search"></i> Buscar OT Lente Optico</button></td>
                                        <td><button type="button" className="btn btn-success btn-sm" onClick={e=>MuestraOrdenesDeTrabajoLC(e)}><i className="fas fa-search"></i> Buscar OT Lente Contacto</button></td>
                                        <td><button type="button" className="btn btn-success btn-sm" onClick={e=>MuestraOrdenesDeTrabajoAyudaVisual(e)}><i className="fas fa-search"></i> Buscar OT Ayuda Visual</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </form>
            </div>
            <Modal size="lg" show={ShowMostrarProducto} onHide={EsconderProductosModal} aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg"> Listado Productos</Modal.Title>
                </Modal.Header>
                <Modal.Body>...</Modal.Body>
            </Modal>


        </section>
    )
}
export default NewVenta