import React, { useState,useEffect } from 'react'
import axios from 'axios'

import { useLocation,useHistory } from "react-router-dom";
const View = () =>{
    const backend = process.env.REACT_APP_BACKEND_URL2
    const location = useLocation();
    const [DatosAtencion,setDatosAtencion]=useState([])
    const id_atencion = location.state.id_atencion
    useEffect(()=>{
        ObtenerDatos(id_atencion)
    },[])
    async function ObtenerDatos(id){
        axios.post(backend+'/api/atencionLcParticular/find/exp',{'idAtencion':id_atencion})
        .then(res=>{
            if(res.data.code===1){
                console.log(res.data.datos)
                setDatosAtencion(res.data.datos)
            }
        })
        .catch((error=>{
            console.log('Error : '+error)
        }))
    }




    
    return(
        <main className="content-wrapper">
            <div className="p-5">
                <div className="row">
                    <label>Orden De Trabajo : {DatosAtencion.OT}</label>
                </div>
                {id_atencion}
            </div>
        </main>
    )
}

export default View