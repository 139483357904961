import React from 'react'
import {Route, Redirect} from "react-router-dom";
import useAuth from '../auth/useAuth'
import Navbar from '../Component/Navbar/Navbar'
import Aside from '../Component/Aside/Aside'
import $ from 'jquery'
export default function PrivateRouter ({component : Component,...rest}){

    document.getElementById('body').classList.remove('hold-transition');
    document.getElementById('body').classList.remove('login-page');
    //document.getElementById('body').classList.add('sidebar-mini layout-fixed');
    $('#body').addClass('sidebar-mini layout-fixed')



 
    const auth = useAuth()
    return(
            <Route {...rest}>
                <div className="wrapper">
                    <Navbar/>
                    <Aside />
                        {auth.token ? 
                        (<Component />)   
                : 
                (<Redirect to='/Login' />)
                
                }
                <div id="sidebar-overlay"></div>
                </div>
                
      
        </Route>

    )
}

