import React,{useState,useEffect} from 'react'
import { useHistory,Redirect } from 'react-router-dom'
import axios from 'axios'
import $ from 'jquery'
import './Sucursal.css'

const Sucursal =(props)=>{
    const history = useHistory()
    document.title="OPTICA REFLEJOS SPA - Seleccion de Sucursal"
    //const Empresa = process.env.REACT_APP_SYSTEM_EMPRESA
    const [local,setLocal]=useState([])
    const Token = sessionStorage.getItem('Token')
    const url = process.env.REACT_APP_BACKEND_URL2
    useEffect(() => {
        Locales(1)
      },[])

    async function Locales(e){
        await axios.post(url+'/api/sucursales/find/all',{},{headers : { authorization :'Bearere '+Token}})
        .then(res=>{
            //console.log(res)
            const code = res.data.code
            switch(code){
                case 1:
                    
                    setLocal(res.data.datos)
                    //console.log(res.data.message)
                break
                case 0:
                    //console.log(res.data.message)
                break
                default :
                    //console.log("lol")
                break
            }
            
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    async function seleccionarLocal(e){
        e.preventDefault();
        const local = $('#Local').val()
        const nombreLocal = $('select[name="Local"] option:selected').text();
        sessionStorage.setItem('Local',local)
        sessionStorage.setItem('NombreLocal',nombreLocal)
        //console.log("evento parado "+nombreLocal)
        //window.location.href='/Home'
        history.push('/Loading',{state : '/Dashboard'})
    } 
    document.getElementById("body").className="";
    return (
        
        <div>
            <form onSubmit={e=>seleccionarLocal(e)}>
            <div className="p-4">
                <div className="card card-widget widget-user card-primary card-outline">
                    <div className="widget-user-header">
                        <div className="widget-user-image">
                            <img className="img-circle " width='77px' src="../img/Simbolo.svg" alt="User Avatar"/>
                        </div>
                    </div>
                    
                    <div className="card-footer">
                        <h2 className="h2 text-center"><label className='text-info'>Bienvenido {props.Nombre}</label></h2>
                        <br/>

                        <select className="form-control" id="Local" name="Local">
                        {local.map(e=>
                          <option key={e._id} value={e._id}>{e.Nombre} - {e.Direccion} - {e.Comuna}</option>
                        )}
                        </select>
                        <br/>
                        <button type="submit" className="btn btn-block btn-success"><i className="fas fa-check"></i> Continuar</button>
                    </div>
                </div>
            </div>
            </form>
            
        </div>
        
    )
}

export default Sucursal