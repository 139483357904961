import {useEffect} from 'react'
import {Link } from 'react-router-dom'
import Options from './Options/Options'
import useAuth from '../../auth/useAuth'
import $ from 'jquery'
import {
    faAngleLeft,
    faChartPie,
    faCopy,
    faEdit, 
    faTable,
    faTachometerAlt,
    faGlasses,
    faTh,
    faCalendarAlt,
    faClipboardList,
    faTree,
  } from '@fortawesome/free-solid-svg-icons';
  import { faCircle as farCircle } from '@fortawesome/free-regular-svg-icons';
  import { Badge, NavItem, NavLink } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Aside.css'




const Aside = (props)=>{
    function AddActive(e,a){
        $('.nav-link').removeClass('active')
        console.log(e)
        $('#'+a).addClass('active')
        

    }
    function AddSubActive(e,b,c){
        $('.nav-link').removeClass('active')
        console.log(e)
        $('#'+b).addClass('active')
        $('#'+c).addClass('active')
    }



    const user = JSON.parse(sessionStorage.getItem('User'))
    const auth = useAuth()
    const Empresa = 'Optica Reflejos'

    
    
    


    useEffect(()=>{
        //Event1(1)
        //console.log(user)
    }) 

    function Event1(e){
        let arrow = document.querySelectorAll(".arrow");
        for (var i = 0; i < arrow.length; i++) {
            arrow[i].addEventListener("click", (e)=>{
                let arrowParent = e.target.parentElement.parentElement;//selecting main parent of arrow
                arrowParent.classList.toggle("showMenu");
            });
        }
        let sidebar = document.querySelector(".sidebar2");
        let sidebarBtn = document.querySelector(".bx-menu");
        sidebarBtn.addEventListener("click", ()=>{
            sidebar.classList.toggle("close2");
        });
    }
 
  
    return(
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <a href="/Dashboard" className="brand-link">
                <img src="/img/ico.png" alt="Optica Reflejos Logo" className="brand-image img-circle elevation-3"/>
                <span className="brand-text font-weight-light" id="nombreEmpresa">{Empresa}</span>
            </a>
            <section className="sidebar">
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        <Options url='/Dashboard' nombre='Dashboard' icon=' nav-icon fas fa-home' />
                        {/* Calendario */}
                        <NavItem>
                            <NavLink tag={Link} to='/Calendario'>
                                <FontAwesomeIcon icon={faCalendarAlt} className='nav-icon' />
                                <p>
                                    Calendario
                                
                                </p>
                            </NavLink>
                        </NavItem>
                        {/* ----------------------- */}
                        {user.Rol === 'Desarrollador' ? 
                          (
                            <li className="nav-header" >Panel Ventas</li>
                          ) : ''
                          }



                        
                        {user.Rol === 'Desarrollador' ? 
                        (
                          <NavItem>
                              <NavLink href='#'>
                                <FontAwesomeIcon icon={faGlasses} className='nav-icon' />
                                  <p>
                                    Inventario Armazones
                                      <FontAwesomeIcon icon={faAngleLeft} className='right' />
                                  </p>
                              </NavLink>
                              <ul className='nav nav-treeview'>
                                <NavItem>
                                  <NavLink tag={Link} to='/Inventario/VerInventario'>
                                    <FontAwesomeIcon icon={farCircle} className='nav-icon' />
                                      <p>Ver Inventario</p>
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink tag={Link} to='/Inventario/IngresarProductos'>
                                    <FontAwesomeIcon icon={farCircle} className='nav-icon' />
                                      <p>Ingreso De Productos</p>
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink tag={Link} to='#'>
                                    <FontAwesomeIcon icon={farCircle} className='nav-icon' />
                                      <p>Registro de Salida</p>
                                  </NavLink>
                                </NavItem>
                              </ul>
                          </NavItem>
                        )
                        : ''
                          }


                          {user.Rol === 'Desarrollador' ? 
                          (
                            
                              
                              <NavItem>
                              <NavLink href='#'>
                                <FontAwesomeIcon icon={faClipboardList} className='nav-icon' />
                                  <p>
                                    Fichas
                                      <FontAwesomeIcon icon={faAngleLeft} className='right' />
                                  </p>
                              </NavLink>
                              <ul className='nav nav-treeview'>
                                <NavItem>
                                  <NavLink tag={Link} to='/Fichas/NuevaFicha'>
                                    <FontAwesomeIcon icon={farCircle} className='nav-icon' />
                                      <p>Nueva Ficha</p>
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink tag={Link} to='/Fichas/BuscarFicha'>
                                    <FontAwesomeIcon icon={farCircle} className='nav-icon' />
                                      <p>Ver Fichas</p>
                                  </NavLink>
                                </NavItem>
                                
                              </ul>
                          </NavItem>
                            
                            
                            
                          )  
                            : ''
                        }

                        {user.Rol === 'Desarrollador' ? 
                        (
                          <NavItem>
                              <NavLink href='#'>
                                <FontAwesomeIcon icon={faClipboardList} className='nav-icon' />
                                  <p>
                                    Inventario Cristales
                                      <FontAwesomeIcon icon={faAngleLeft} className='right' />
                                  </p>
                              </NavLink>
                              <ul className='nav nav-treeview'>
                                <NavItem>
                                  <NavLink tag={Link} to='/InventarioCristales/VerInventario'>
                                    <FontAwesomeIcon icon={farCircle} className='nav-icon' />
                                      <p>Ver Inventario</p>
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink tag={Link} to='#'>
                                    <FontAwesomeIcon icon={farCircle} className='nav-icon' />
                                      <p>Ver Fichas</p>
                                  </NavLink>
                                </NavItem>
                                
                              </ul>
                          </NavItem>
                        ): 
                        
                        
                        
                        ''  
                      }

                        {user.Rol === 'Desarrollador' ? 
                        (
                          <NavItem>
                              <NavLink href='#'>
                                <FontAwesomeIcon icon={faClipboardList} className='nav-icon' />
                                  <p>
                                    Atenciones Junaeb
                                      <FontAwesomeIcon icon={faAngleLeft} className='right' />
                                  </p>
                              </NavLink>
                              <ul className='nav nav-treeview'>
                                <NavItem>
                                  <NavLink tag={Link} to='/Contactologia/Junaeb/NuevaAtencion'>
                                    <FontAwesomeIcon icon={farCircle} className='nav-icon' />
                                      <p>Nueva Atencion</p>
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink tag={Link} to='/Contactologia/Junaeb/BuscarAtencion'>
                                    <FontAwesomeIcon icon={farCircle} className='nav-icon' />
                                      <p>Buscar Atencion</p>
                                  </NavLink>
                                </NavItem>
                                
                              </ul>
                          </NavItem>
                        ): 
                        
                        
                        
                        ''  
                      }


                        
                    </ul>
                </nav>
                
            </section>
        </aside>
    )
}

export default Aside