import { BrowserRouter as Router,Route,Switch,Redirect,HashRouter } from "react-router-dom"


import PrivateRouter from "./PrivateRouter"
import PrivatePartRouter from "./PrivatePartRouter"
import Error from "../Views/Error/Error"
import Login from '../Views/Login/Login'
import Loading from '../Views/Loading/Loading'
import Calendario from '../Views/Calendario/Calendario'
import Dashboard from '../Views/Dashboard/Dashboard'
import Sucursal from '../Views/Sucursal/Sucursal'

import InventarioPrincipalVerInventario from '../Views/InventarioSucursal/View/View'
import InventarioPrincipalIngresoProductos from '../Views/InventarioSucursal/Insert/Insert'
import FichasNew from '../Views/Fichas/New/New'
import FichasFind from '../Views/Fichas/Find/Find'
import NuevaVenta from '../Views/PuntoDeVenta/New/New'

import ContactologiaJunaebNuevaAtencion from '../Views/Contactologia/Junaeb/New/New'
import ContactologiaJunaebVerAtencion from '../Views/Contactologia/Junaeb/View/View'
import InventarioCristalesView from '../Views/InventarioCristales/View/View'

const MainRouter = () =>{
    return(
            <Router>
                <Switch>
                    <PrivateRouter exact path='/Dashboard' component={Dashboard} />
                    <PrivateRouter exact path='/Calendario' component={Calendario} />
                    <PrivateRouter exact path='/Ventas/NuevaVenta' component={NuevaVenta} />
                    <PrivateRouter exact path='/Inventario/VerInventario' component={InventarioPrincipalVerInventario} />
                    <PrivateRouter exact path='/Inventario/IngresarProductos' component={InventarioPrincipalIngresoProductos} />
                    <PrivateRouter exact path='/Fichas/NuevaFicha' component={FichasNew} />
                    <PrivateRouter exact path='/Fichas/BuscarFicha' component={FichasFind} />
                    <PrivateRouter exact path='/InventarioCristales/VerInventario' component={InventarioCristalesView} />
                    <PrivateRouter exact path='/Contactologia/Junaeb/NuevaAtencion' component={ContactologiaJunaebNuevaAtencion} />
                    <PrivateRouter exact path='/Contactologia/Junaeb/VerAtencion' component={ContactologiaJunaebVerAtencion} />
                    <PrivatePartRouter exact path='/Sucursal' component={Sucursal} />
                    
                    
                    <Route exact path='/Loading'>
                        <Loading />
                    </Route>
                    <Route exact path='/'>
                        <Redirect to='/Login' />
                    </Route>
                    <Route exact path='/Login'>
                        <Login />
                    </Route>
                    <Route path='*'>
                        <Error/>
                    </Route>
                </Switch>
            </Router>

    )
}

export default MainRouter