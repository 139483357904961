import React,{useState,useEffect} from 'react'
import $ from 'jquery'
import {Tabs,Tab,Row,Col,Nav} from 'react-bootstrap'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const New = () =>{
    const backend = process.env.REACT_APP_BACKEND_URL2
    const Token = sessionStorage.getItem('Token')
    const [TabLejosState,setTabLejosState]=useState(true)
    const [TabCercaState,setTabCercaState]=useState(false)
    const [ExistePaciente,setExistePaciente]=useState(false)
    useEffect(() => {
        FuncionesCarga(1)
    },[])
    const [DatosPaciente,setDatosPaciente]=useState({
        Rut : '',
        Nombre : '',
        FechaNacimiento : '',
        Actividad : '',
        Telefono : '',
        Direccion : '',
        Comuna : '',
        Correo : '',
        idPaciente : 0,
    })
    const [Anamnesis,setAnamnesis]=useState({
        Motivo_Consulta : '',
        Antecedentes_Oculares : '',
        Antecedentes_Enf_Generales : '',
        Otros_Antecedentes : '',
        Profesional : ''
    })
    const [DatosMedicos,setDatosMedicos]=useState({
        RxUso_OD_Sph : '',
        RxUso_OD_Cyl:'',
        RxUso_OD_Axis:'',
        RxUso_OD_Add:'',
        RxUso_OI_Sph:'',
        RxUso_OI_Cyl:'',
        RxUso_OI_Axis:'',
        RxUso_OI_Add:'',
        AV_Lejos_OD_SC:'',
        AV_Lejos_OD_CC:'',
        AV_Lejos_OD_PH:'',
        AV_Lejos_OI_SC:'',
        AV_Lejos_OI_CC:'',
        AV_Lejos_OI_PH:'',
        AV_Cerca_OD_SC:'',
        AV_Cerca_OD_CC:'',
        AV_Cerca_OD_PH:'',
        AV_Cerca_OI_SC:'',
        AV_Cerca_OI_CC:'',
        AV_Cerca_OI_PH:'',
        Bio_OD:'',
        Bio_OI:'',
        Kero_OD_K1:'',
        Kero_OD_K2:'',
        Kero_OD_Eje:'',
        Kero_OI_K1:'',
        Kero_OI_K2:'',
        Kero_OI_Eje:'',
        Ref_OD_Sph:'',
        Ref_OD_Cyl:'',
        Ref_OD_Axis:'',
        Ref_DP_Lejos:'',
        Ref_OD_AVLejos:'',
        Ref_OD_AVCerca:'',
        Ref_OI_Sph:'',
        Ref_OI_Cyl:'',
        Ref_OI_Axis:'',
        Ref_DP_Cerca:'',
        Ref_OI_AVLejos:'',
        Ref_OI_AVCerca:'',
        Ref_Indicaciones:'',
        Ref_Observacion:''

    })

    const handleInputChangeDatosPaciente = (event) => {
        
        setDatosPaciente({
            ...DatosPaciente,
            [event.target.name] : event.target.value
        })
    }
    const handleCaptureDatosPaciente = (event) => {
       
        setDatosPaciente({
            ...DatosPaciente,
            [event.target.name] : event.target.value
        })
    }
    const handleInputChangeAnamnesis = (event) => {
        
        setAnamnesis({
            ...Anamnesis,
            [event.target.name] : event.target.value
        })
    }
    const handleCaptureAnamnesis = (event) => {
        
        setAnamnesis({
            ...Anamnesis,
            [event.target.name] : event.target.value
        })
    }
    const handleInputChangeDatosMedicos = (event) => {
        
        setDatosMedicos({
            ...DatosMedicos,
            [event.target.name] : event.target.value
        })
    }
    const handleCaptureDatosMedicos = (event) => {
        
        setDatosMedicos({
            ...DatosMedicos,
            [event.target.name] : event.target.value
        })
    }
    function lejosChange(e){
        if(e===true){
            setTabLejosState(true)
            $('#TablaLejos').removeClass('d-none')
            //$('#tab-Lejos').removeClass('d-none')
        }else{
            setTabLejosState(false)
            $('#TablaLejos').addClass('d-none')
            $('#tab-Lejos').addClass('d-none')
            $('#AV_Lejos_OD_SC').val('')
            $('#AV_Lejos_OD_CC').val('')
            $('#AV_Lejos_OD_PH').val('')
            $('#AV_Lejos_OI_SC').val('')
            $('#AV_Lejos_OI_CC').val('')
            $('#AV_Lejos_OI_PH').val('')
            setDatosMedicos({
                ...DatosMedicos,
                AV_Lejos_OD_SC : '',
                AV_Lejos_OD_CC : '',
                AV_Lejos_OD_PH : '',
                AV_Lejos_OI_SC : '',
                AV_Lejos_OI_CC : '',
                AV_Lejos_OI_PH : '',
            })
        }
    }
    
    function cercaChange(e){
        if(e===true){
            setTabCercaState(true)
            $('#RxUso_OD_Add').removeAttr('readOnly')
            $('#RxUso_OI_Add').removeAttr('readOnly')
            $('#TablaCerca').removeClass('d-none')
            //$('#tab-Cerca').removeClass('d-none')
        }else{
            setTabCercaState(false)
            $('#RxUso_OD_Add').val('')
            $('#RxUso_OD_Add').attr('readOnly','true')
            $('#RxUso_OI_Add').val('')
            $('#RxUso_OI_Add').attr('readOnly','true')
            $('#MainTabCerca').addClass('d-none')
            $('#TablaCerca').addClass('d-none')
            $('#AV_Cerca_OD_SC').val('')
            $('#AV_Cerca_OD_CC').val('')
            $('#AV_Cerca_OD_PH').val('')
            $('#AV_Cerca_OI_SC').val('')
            $('#AV_Cerca_OI_CC').val('')
            $('#AV_Cerca_OI_PH').val('')
            setDatosMedicos({
                ...DatosMedicos,
                AV_Cerca_OD_SC : '',
                AV_Cerca_OD_CC : '',
                AV_Cerca_OD_PH : '',
                AV_Cerca_OI_SC : '',
                AV_Cerca_OI_CC : '',
                AV_Cerca_OI_PH : '',
                RxUso_OD_Add : '',
                RxUso_OI_Add: ''
            })
        }
    }
    async function BuscarPaciente(e){
        let Rut = $('#Rut').val()
        await axios.post(backend+'/api/pacientes/find/exp',{'Rut':Rut},{headers : { authorization :'Bearere '+Token}})
        .then(res=>{
            if(res.data.code ===1){
                toast.success('Paciente Encontrado ....',{
                    autoClose: 2000,
                    pauseOnHover: false,
                });
                setExistePaciente(true)
                let datosPaciente=res.data.datos
                $('#Nombre').val(datosPaciente.Nombre)
                $('#Direccion').val(datosPaciente.Direccion)
                $('#idPaciente').val(datosPaciente._id)
                $('#Comuna').val(datosPaciente.Comuna)
                $('#FechaNacimiento').val(datosPaciente.FechaNacimiento)
                $('#Actividad').val(datosPaciente.Actividad)
                $('#Telefono').val(datosPaciente.Telefono)
                $('#Correo').val(datosPaciente.Correo)
                setDatosPaciente({
                    ...DatosPaciente,
                    idPaciente : datosPaciente._id,
                    Nombre : datosPaciente.Nombre,
                    Direccion : datosPaciente.Direccion,
                    Telefono : datosPaciente.Telefono,
                    Actividad : datosPaciente.Actividad,
                    Correo : datosPaciente.Correo,
                    Comuna : datosPaciente.Comuna,
                    FechaNacimiento : datosPaciente.FechaNacimiento
                })
            }else{
                toast.error('Paciente No Existe ....',{
                    autoClose: 2000,
                    pauseOnHover: false,
                });
                setExistePaciente(false)
                $('#Nombre').val('')
                $('#Direccion').val('')
                $('#FechaNacimiento').val(0)
                $('#idPaciente').val(0)
                $('#Comuna').val('')
                $('#Actividad').val('')
                $('#Telefono').val('')
                $('#Correo').val('')
                setDatosPaciente({
                    ...DatosPaciente,
                    idPaciente : 0,
                    Nombre : '',
                    Direccion : '',
                    Telefono : '',
                    Actividad : '',
                    Correo : '',
                    Comuna : '',
                    FechaNacimiento : ''
                })
            }
            
        })
        .catch((error)=>{
            console.log('Error => '.error)
        })
    }
    function FuncionesCarga(e){
        $('.limitocoma').on('keypress',function(tecla){
            //console.log(tecla.charCode)
            if(tecla.charCode == 44){
                return false
            }
        })
    }
    async function RegistrarAtencion(e){
        e.preventDefault()
        let DatosInsertPaciente=DatosPaciente
        let error = false
        if(!ExistePaciente){
            let lista = await ComprobarDatosIngresar(1)
            error = lista[0]
            DatosInsertPaciente = lista[1] 
            
        }

        if(error){
            console.log('Stop')
        }else{
            await InsertarFicha(1,DatosInsertPaciente)
        }
        
    }
    async function InsertarFicha(e,data){
        const Composicion ={
            DatosPaciente : data,
            Anamnesis : Anamnesis,
            DatosMedicos : DatosMedicos
        }
        await axios.post(backend+'/api/fichas/new',{'Datos':Composicion},{headers : { authorization :'Bearere '+Token}})
        .then(res=>{
            console.log(res.data)
            if(res.data.code===1){
                toast.error('Ficha Creada ....',{
                    autoClose: 2000,
                    pauseOnHover: false,
                });
                limpiar(1)

            }
        })
        .catch((error)=>{
            console.log('Error => '+error)
        })
    }

    async function ComprobarDatosIngresar(e){
        let DatosIncompletos = []
        let DatosInsertPaciente=''
        let ErrorDetected = false
        if(DatosPaciente.Rut.length===0){
            DatosIncompletos.push('Rut')
            ErrorDetected=true
        }
        if(DatosPaciente.Nombre.length===0){
            DatosIncompletos.push('Nombre')
            ErrorDetected=true
        }
        if(DatosPaciente.Telefono.length===0){
            DatosIncompletos.push('Fono')
            ErrorDetected=true
        }
        if(DatosPaciente.Actividad.length===0){
            DatosIncompletos.push('Actividad')
            ErrorDetected=true
        }
        if(ErrorDetected){
            for(let x=0;x<DatosIncompletos.length;x++){
                toast.error('Favor Complete El Siguiente Campo : '+DatosIncompletos[x],{
                    autoClose: 2000,
                    pauseOnHover: false,
                });
            }
        }else{
            let listado = await RegistrarPaciente(1)
            ErrorDetected = listado[0]
            DatosInsertPaciente = listado[1]
        }
        return [ErrorDetected,DatosInsertPaciente]
    }

    async function RegistrarPaciente(e){
        let estado = true
        const datosRegistroPaciente ={
            Rut : $('#Rut').val(),
            Nombre : $('#Nombre').val(),
            Telefono : $('#Telefono').val(),
            Direccion : $('#Direccion').val(),
            Comuna : $('#Comuna').val(),
            Correo : $('#Correo').val(),
            Actividad : $('#Actividad').val(),
            FechaNacimiento : $('#FechaNacimiento').val(),
        }
        let datosPacienteReturn=''
        await axios.post(backend+'/api/clientes/new',{'DatosPaciente':datosRegistroPaciente},{headers : { authorization :'Bearere '+Token}})
        .then(res=>{
            if(res.data.code===1){
                let idAux = res.data.datos._id
                console.log(idAux)
                estado=false
                toast.success('Paciente Registrado ....',{
                    autoClose: 2000,
                    pauseOnHover: false,
                });
                
                setExistePaciente(true)
                let NewData = {
                    ...DatosPaciente,
                    idPaciente : idAux
                }
                setDatosPaciente({
                    ...DatosPaciente,
                    idPaciente : idAux
                })
                datosPacienteReturn=NewData
                setDatosPaciente(NewData)
            }else{
                estado=true
                setExistePaciente(false)
                toast.error('Paciente no pudo ser Registrado ....',{
                    autoClose: 2000,
                    pauseOnHover: false,
                });
            }
        })
        .catch((error)=>{
            console.log('Error => '+error)
        })



        return [estado,datosPacienteReturn]
    }
    function limpiar(){
        document.getElementById("formulario").reset();
        
    }



    return(
        <main className="content-wrapper">
            

            <form id="formulario">
            <div className="p-4 l-4">
            <tb /><button type="button" onClick={e=>RegistrarAtencion(e)} className='btn btn-success'>Guardar Ficha</button>
            <ToastContainer />
                    <Tabs defaultActiveKey="Paciente" id="Tab-Ficha-New" className="mb-3">
                        <Tab eventKey="Paciente" title="Paciente">
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td><label className="text-info">Rut Paciente *</label></td>
                                        <td><input type="text" className="form-control input-sm auto2 limitocoma" onBlur={e=>BuscarPaciente(e)} id="Rut" required onChange={handleInputChangeDatosPaciente}  name="Rut" placeholder="12134567-8" />
                                            <input type="hidden" id="idPaciente" name="idPaciente" value={DatosPaciente.idPaciente}/>
                                        </td>
                                        <td><button type="button" className="btn btn-success" onClick={e=>BuscarPaciente(e)}>Buscar Paciente</button></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-info">Nombre Paciente *</label></td>
                                        <td colSpan="2"><input type="text" className="form-control limitocoma" id="Nombre" required onChange={handleInputChangeDatosPaciente} onBlur={handleCaptureDatosPaciente} name="Nombre" placeholder="Nombre del Paciente" /></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-info">Fecha Nacimiento</label></td>
                                        <td colSpan="2"><input type="date" className="form-control" id="FechaNacimiento" name="FechaNacimiento" onChange={handleInputChangeDatosPaciente} onBlur={handleCaptureDatosPaciente} /> </td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-info">Direccion</label></td>
                                        <td colSpan="2"><input type="text" className="form-control limitocoma" id="Direccion" onChange={handleInputChangeDatosPaciente} onBlur={handleCaptureDatosPaciente} name="Direccion" /> </td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-info">Comuna</label></td>
                                        <td colSpan="2"><input type="text" className="form-control limitocoma" id="Comuna" onChange={handleInputChangeDatosPaciente} onBlur={handleCaptureDatosPaciente} name="Comuna" /> </td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-info">Telefono *</label></td>
                                        <td colSpan="2"><input type="text" className="form-control limitocoma" required id="Telefono" onChange={handleInputChangeDatosPaciente} onBlur={handleCaptureDatosPaciente} name="Telefono" /> </td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-info">Actividad *</label></td>
                                        <td colSpan="2"><input type="text" className="form-control limitocoma" required id="Actividad" onChange={handleInputChangeDatosPaciente} onBlur={handleCaptureDatosPaciente} name="Actividad" /> </td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-info">Correo</label></td>
                                        <td colSpan="2"><input type="text" className="form-control limitocoma" id="Correo" onChange={handleInputChangeDatosPaciente} onBlur={handleCaptureDatosPaciente} name="Correo" /> </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Tab>
                        <Tab eventKey="Anamnesis" title="Anamnesis">
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td width="40%"><label className="text-info">Motivo Consulta</label></td>
                                        <td><textarea className="form-control limitocoma" id="Motivo_Consulta" name ="Motivo_Consulta" onChange={handleInputChangeAnamnesis} onBlur={handleCaptureAnamnesis} rows="3"></textarea></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-info">Antecedentes Oculares</label></td>
                                        <td><textarea className="form-control limitocoma" id="Antecedentes_Oculares" name="Antecedentes_Oculares" onChange={handleInputChangeAnamnesis} onBlur={handleCaptureAnamnesis} rows="3"></textarea></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-info">Antecedentes Enfermedades Generales</label></td>
                                        <td><textarea className="form-control limitocoma" id="Antecedentes_Enf_Generales" name="Antecedentes_Enf_Generales" onChange={handleInputChangeAnamnesis} onBlur={handleCaptureAnamnesis} rows="3"></textarea></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-info">Otros Antecedentes</label></td>
                                        <td><textarea className="form-control limitocoma" id="Otros_Antecedentes" name="Otros_Antecedentes" onChange={handleInputChangeAnamnesis} onBlur={handleCaptureAnamnesis} rows="3"></textarea></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-info">Profesional</label></td>
                                        <td><select className="form-control" id="Profesional" name="Profesional" onChange={handleInputChangeAnamnesis} onBlur={handleCaptureAnamnesis}>
                                                <option value="Daniela Alvarado">Daniela Alvarado</option>
                                                <option value="Irene Valdenegro">Irene Valdenegro</option>
                                                <option value="Alvaro Madrid">Alvaro Madrid</option>
                                                <option value="Vania Iturra">Vania Iturra</option>
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Tab>
                        <Tab eventKey="DatosMedicos" title="Datos Medicos">
                            <table className="table table-bordered">
                                <tbody>
                                    <tr>
                                        <td width="30%">
                                            <label className="text-info">Rx En Uso</label>
                                            <div className="form-group clearfix">
                                                <div className="icheck-primary d-inline">
                                                    <input type="checkbox" id="checkboxPrimary1" defaultChecked onChange={e=>lejosChange(e.target.checked)}/>
                                                    <label htmlFor="checkboxPrimary1">Lejos</label>
                                                </div>
                                                <div className="icheck-primary d-inline">
                                                    <input type="checkbox" id="checkboxPrimary2" onChange={e=>cercaChange(e.target.checked)}/>
                                                    <label htmlFor="checkboxPrimary2">Cerca</label>
                                                </div>
                                            </div>
                                        </td> 
                                        <td className="text-center"><label className="text-info">Esf</label></td>
                                        <td className="text-center"><label className="text-info">Cyl</label></td>
                                        <td className="text-center"><label className="text-info">Eje</label></td>
                                        <td className="text-center"><label className="text-info">Add</label></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-info">OD</label></td>
                                        <td><input type="text" id="RxUso_OD_Sph" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} name="RxUso_OD_Sph" className="form-control input-sm limitocoma"/>  </td>
                                        <td><input type="text" id="RxUso_OD_Cyl" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} name="RxUso_OD_Cyl" className="form-control input-sm limitocoma"/>  </td>
                                        <td><input type="text" id="RxUso_OD_Axis" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} name="RxUso_OD_Axis" className="form-control input-sm limitocoma"/>  </td>
                                        <td><input type="text" id="RxUso_OD_Add" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} readOnly name="RxUso_OD_Add" className="form-control input-sm limitocoma"/>  </td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-info">OI</label></td>
                                        <td><input type="text" id="RxUso_OI_Sph" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} name="RxUso_OI_Sph" className="form-control input-sm limitocoma"/>  </td>
                                        <td><input type="text" id="RxUso_OI_Cyl" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} name="RxUso_OI_Cyl" className="form-control input-sm limitocoma"/>  </td>
                                        <td><input type="text" id="RxUso_OI_Axis" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} name="RxUso_OI_Axis" className="form-control input-sm limitocoma"/>  </td>
                                        <td><input type="text" id="RxUso_OI_Add" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} readOnly name="RxUso_OI_Add" className="form-control input-sm limitocoma"/>  </td> 
                                    </tr>
                                </tbody>
                            </table>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="Lejos">
                                <Row>
                                    <Col sm={3}>
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item>
                                                {TabLejosState ? 
                                                (<Nav.Link eventKey="Lejos" id="MainTabLejos">Lejos</Nav.Link>)
                                                    :
                                                (<Nav.Link eventKey="Lejos" id="MainTabLejos" disabled>Lejos</Nav.Link>)
                                            }   
                                            </Nav.Item>
                                            <Nav.Item>
                                                {TabCercaState ? 
                                                (<Nav.Link eventKey="Cerca" >Cerca</Nav.Link>)
                                                :
                                                (<Nav.Link eventKey="Cerca" disabled >Cerca</Nav.Link>)    
                                                }
                                                
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col sm={9}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="Lejos">
                                                <table className="table table-bordered" id="TablaLejos">
                                                    <thead>
                                                        <tr>
                                                            <td colSpan="4" className="text-center"><label className="text-info">AV Lejos</label></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center"><label className="text-info">VL</label></td>
                                                            <td className="text-center"><label className="text-info">SC</label></td>
                                                            <td className="text-center"><label className="text-info">CC</label></td>
                                                            <td className="text-center"><label className="text-info">PH</label></td>
                                                        </tr>
                                                        <tr>
                                                            <td><label className="text-info">OD</label></td>
                                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="AV_Lejos_OD_SC" name="AV_Lejos_OD_SC" className="form-control input-sm limitocoma"/>  </td>
                                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="AV_Lejos_OD_CC" name="AV_Lejos_OD_CC" className="form-control input-sm limitocoma"/></td>
                                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="AV_Lejos_OD_PH" name="AV_Lejos_OD_PH" className="form-control input-sm limitocoma"/></td>
                                                        </tr>
                                                        <tr>
                                                            <td><label className="text-info">OI</label></td>
                                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="AV_Lejos_OI_SC" name="AV_Lejos_OI_SC" className="form-control input-sm limitocoma"/>  </td>
                                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="AV_Lejos_OI_CC" name="AV_Lejos_OI_CC" className="form-control input-sm limitocoma"/></td>
                                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="AV_Lejos_OI_PH" name="AV_Lejos_OI_PH" className="form-control input-sm limitocoma"/></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Cerca">
                                                <table className="table table-bordered" id="TablaCerca">
                                                    <thead>
                                                        <tr>
                                                            <td colSpan="4" className="text-center"><label className="text-info">AV Cerca</label></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center"><label className="text-info">VP</label></td>
                                                            <td className="text-center"><label className="text-info">SC</label></td>
                                                            <td className="text-center"><label className="text-info">CC</label></td>
                                                            <td className="text-center"><label className="text-info">PH</label></td>
                                                        </tr>
                                                        <tr>
                                                            <td><label className="text-info">OD</label></td>
                                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="AV_Cerca_OD_SC" name="AV_Cerca_OD_SC" className="form-control input-sm limitocoma"/>  </td>
                                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="AV_Cerca_OD_CC" name="AV_Cerca_OD_CC" className="form-control input-sm limitocoma"/></td>
                                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="AV_Cerca_OD_PH" name="AV_Cerca_OD_PH" className="form-control input-sm limitocoma"/></td>
                                                        </tr>
                                                        <tr>
                                                            <td><label className="text-info">OI</label></td>
                                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="AV_Cerca_OI_SC" name="AV_Cerca_OI_SC" className="form-control input-sm limitocoma"/>  </td>
                                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="AV_Cerca_OI_CC" name="AV_Cerca_OI_CC" className="form-control input-sm limitocoma"/></td>
                                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="AV_Cerca_OI_PH" name="AV_Cerca_OI_PH" className="form-control input-sm limitocoma"/></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                            
                                <br/>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td colSpan="2" className="text-center"><label className="text-info">Biomicroscopia</label></td>
                                        </tr>
                                        <tr>
                                            <td><label className="text-info">OD</label></td>
                                            <td><input type="text" id="Bio_OD" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} name="Bio_OD" className="form-control input-sm limitocoma"/>  </td>
                                            </tr>
                                        <tr>
                                            <td><label className="text-info">OI</label></td>
                                            <td><input type="text" id="Bio_OI" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} name="Bio_OI" className="form-control input-sm limitocoma"/></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th colSpan="4" className="text-center"><label className="text-info">Keratometria</label></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td width="10%"><label className="text-info">OD</label></td>
                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="Kero_OD_K1" name="Kero_OD_K1" className="form-control input-sm limitocoma"/></td>
                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="Kero_OD_K2" name="Kero_OD_K2" className="form-control input-sm limitocoma"/></td>
                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="Kero_OD_Eje" name="Kero_OD_Eje" className="form-control input-sm limitocoma"/></td>
                                        </tr>
                                        <tr>
                                            <td width="10%"><label className="text-info">OI</label></td>
                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="Kero_OI_K1" name="Kero_OI_K1" className="form-control input-sm limitocoma"/></td>
                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="Kero_OI_K2" name="Kero_OI_K2" className="form-control input-sm limitocoma"/></td>
                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="Kero_OI_Eje" name="Kero_OI_Eje" className="form-control input-sm limitocoma"/></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th colSpan="7" className="text-center"><label className="text-info">Refraccion</label></th>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th><label className="text-info">Esf</label></th>
                                            <th><label className="text-info">Cyl</label></th>
                                            <th><label className="text-info">Eje</label></th>
                                            <th><label className="text-info">Dp</label></th>
                                            <th><label className="text-info">AV Lejos</label></th>
                                            <th><label className="text-info">AV Cerca</label></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td width="10%"><label className="text-info">OD</label></td>
                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="Ref_OD_Sph" name="Ref_OD_Sph" className="form-control input-sm limitocoma"/></td>
                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="Ref_OD_Cyl" name="Ref_OD_Cyl" className="form-control input-sm limitocoma"/></td>
                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="Ref_OD_Axis" name="Ref_OD_Axis" className="form-control input-sm limitocoma"/></td>
                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="Ref_DP_Lejos" name="Ref_DP_Lejos" placeholder="Lejos" className="form-control input-sm limitocoma"/></td>
                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="Ref_OD_AVLejos" name="Ref_OD_AVLejos" className="form-control input-sm limitocoma"/></td>
                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="Ref_OD_AVCerca" name="Ref_OD_AVCerca" className="form-control input-sm limitocoma"/></td>
                                        </tr>
                                        <tr>
                                            <td width="10%"><label className="text-info">OI</label></td>
                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="Ref_OI_Sph" name="Ref_OI_Sph" className="form-control input-sm limitocoma"/></td>
                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="Ref_OI_Cyl" name="Ref_OI_Cyl" className="form-control input-sm limitocoma"/></td>
                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="Ref_OI_Axis" name="Ref_OI_Axis" className="form-control input-sm limitocoma"/></td>
                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="Ref_DP_Cerca" name="Ref_DP_Cerca" placeholder="Cerca" className="form-control input-sm limitocoma"/></td>
                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="Ref_OI_AVLejos" name="Ref_OI_AVLejos" className="form-control input-sm limitocoma"/></td>
                                            <td><input type="text" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="Ref_OI_AVCerca" name="Ref_OI_AVCerca" className="form-control input-sm limitocoma"/></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td><label className="text-info">Indicaciones</label></td>
                                            <td><textarea className="form-control limitocoma" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="Ref_Indicaciones" name="Ref_Indicaciones" rows="3"></textarea></td>
                                        </tr>
                                        <tr>
                                            <td><label className="text-info">Observacion</label></td>
                                            <td><textarea className="form-control limitocoma" onChange={handleInputChangeDatosMedicos} onBlur={handleCaptureDatosMedicos} id="Ref_Observacion" name="Ref_Observacion" rows="3"></textarea></td>
                                        </tr>
                                    </tbody>
                                </table>
                        </Tab>
                    </Tabs>
            </div>
            </form>
        </main>
    )
}

export default New