import React,{createContext, useState,useEffect} from 'react'
import $ from 'jquery'
export const AuthContext = createContext()






const AuthProvider = ({children})=>{
    const [user,setUser]=useState(
        JSON.parse(sessionStorage.getItem('User')) || null
    )
    const [DarkMode,setDarkMode]=useState(false)
    const [Logged,setLogged]=useState(false)
    const [token,setToken]=useState(
        sessionStorage.getItem('Token') || null
    )
    useEffect(()=>{
        try{
            //console.log('ejecutando')
            //console.log('user : '+user)
            if(user!=null){
                sessionStorage.setItem('User',JSON.stringify(user))
            }else{
                //console.log('User es nulo')
                sessionStorage.removeItem('User')
            }
            if(token!=null){
                sessionStorage.setItem('Token',token)
            }else{
                //console.log('Token es nulo')
                sessionStorage.removeItem('Token')
            }
            
            
            
        }catch(error){
            //console.log(error)
            sessionStorage.clear();
            
        }

         
    }, [token, user])
    
    
    
    const contextValue = {
        user,
        token,
        login(Token,state,datos){
            setToken(Token)
            setLogged(state)
            setUser(datos)
            return true
        },
        FunctdarkMode(){
            if(DarkMode){
                setDarkMode(false)
                $('#body').removeClass('dark-mode')
                $('.home-section2').css({'background':'white'})
                $('#btnDarkMode').html('Dark Mode OFF')
                
            }else{
                setDarkMode(true)
                $('#body').addClass('dark-mode')
                $('.home-section2').css({'background':'black'})
                $('#btnDarkMode').html('Dark Mode ON')
                

            }
        },
        logout(){
            setUser(null)
            setToken(null)
            
            sessionStorage.clear();
        },
        isLogged(){
            if(user!=null && token!=null){
                return true
            }else{
                return null
            }
            
        }
        
    }
    return <AuthContext.Provider value={contextValue}>
        {children}
    </AuthContext.Provider>
}

export default AuthProvider