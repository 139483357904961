import React,{useState,useEffect} from 'react'
import axios from 'axios'
import moment from 'moment'
import $ from 'jquery'
import {Modal,Button,CloseButton} from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';


import 'react-toastify/dist/ReactToastify.css';
const backend = process.env.REACT_APP_BACKEND_URL2
const Token = sessionStorage.getItem('Token')
const customId2 = "custom-id-yes2";
const View = () =>{
    const user = JSON.parse(sessionStorage.getItem('User'))
    const [AbrirModal1,setAbrirModal1]=useState(false)
    const [AbrirModal2,setAbrirModal2]=useState(false)
    const AbroModal1 = ()=>setAbrirModal1(true)
    const CerrarModal1 = () => setAbrirModal1(false)
    const AbroModal2 = ()=>setAbrirModal2(true)
    const CerrarModal2 = () => setAbrirModal2(false)

    useEffect(()=>{
        CargarDatatable()
    },[])

    function CargarDatatable(e){
        try{
            const table = $('#TablaCristales').DataTable( {
  
                ajax:{     
                  url : backend+'/api/inventarioCristales/find/all',
                  type : 'POST',
                  data : {
                      'Sucursal':sessionStorage.getItem('Local')
                  },
                  headers : { authorization :'Bearere '+Token},
                  dataSrc:''
                },
              columns: [
                { data: '_id' ,'visible': false},
                { data: 'QR' },
                {data : null,
                  render : function ( data, type, row, meta ) {
                    return   data.Material+' '+data.Sph+' '+data.Cyl;
                  }},
                { data: 'Sph','visible' : false },
                { data: 'Cyl','visible' : false },
                { data : 'Material','visible' : false},
                { data : 'Rango','visible' : false},
                { data: 'Cantidad' },
                {data : null,
                  render : function ( data, type, row, meta ) {
                    return   moment(data.LastUpdate).format("DD-MM-YYYY hh:mm a");
                  },'visible':false}
                  
                  
              ],
              language: {
                url: 'http://cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json'
                },
              retrieve: true,
              autoWidth: false,
              processing: true,
              autoFill: true,
              colReorder: true,
              fixedHeader: true,
              lengthMenu: [
                  [   10, 25, 50,-1],
                  [ '10 Filas', '25 Filas', '50 Filas','Todo'  ]
              ],
              keys: true,
              responsive: true,
              dom: 'Bfrtip',
              buttons: [
                  'copy', 'excel', 'pdf','csv','pageLength','print',{
                          text: '<i className="fas fa-sync"></i> Actualizar',
                          action: function ( e, dt, node, config ) {
                              $('#TablaCristales').DataTable().ajax.reload( null, false );
                          }
                      },
                      {
                        text: '<i className="fas fa-sync"></i> Crear Nuevo Cristales',
                        action: function ( e, dt, node, config ) {
                            AbroModal1(1)
                        }
                    }
              ],
              
            });
            $('#TablaCristales tbody').on('click', 'tr', function () {
                //console.log(props.Rol)
                var data = table.row(this).data();
                if(user.Rol==='Administrador' || user.Rol ==='Desarrollador'){
                    console.log(user.Rol)
                    AbroModal2(1)
                    $('#ModStockQr').val(data.QR)
                    $('#ModStockID').val(data._id)
                    $('#ModStockSph').val(data.Sph)
                    $('#ModStockCyl').val(data.Cyl)
                    $('#ModStockMaterial').val(data.Material)
                    $('#ModStockRango').val(data.Rango)
                    $('#ModStockCantidad').val(data.Cantidad)
                    
                }else{
                    //$('#ModalIngresoCristal').modal('show');
                    /*Toast.fire({
                        icon: 'error',
                        title: 'No Tienes Permiso Para Realizar Cambios',
                        timerProgressBar : true,
                        animation : true,
                      })*/
                }
                
                 
                
                
                  //alert( 'You clicked on '+data._id+'\'s row' );
              } );
            

        }catch(error){
            console.log('Error try : ' + error)
        }
    }

    async function isValid(e){
        const id = e.target.id
        if($('#'+id).val()){
            
            $('#'+id).addClass('is-valid')
            $('#'+id).removeClass('is-invalid')
        }else{
            $('#'+id).addClass('is-invalid')
            $('#'+id).removeClass('is-valid')
        } 
    }
    async function EsconderQR(e){
        $('#infoQR').addClass('d-none')
        $('#infoQR').html('')
    } 
    async function existQR(e){
        e.preventDefault()
        if($('#NewQr').val()){
            const findQr =$('#NewQr').val()
            await axios.post(backend+'/api/inventarioCristales/find/qr',{'DatosQR':findQr},{headers : { authorization :'Bearere '+Token}})
            .then(res=>{
                if(res.data.code===0){
                    $('#NewQr').removeClass('is-invalid')     
                    $('#NewQr').addClass('is-valid')
                    $('#state').val(0)
                    $('#infoQR').addClass('d-none')
                }else{
                    $('#infoQR').removeClass('d-none')
                    $('#NewQr').removeClass('is-valid')
                    $('#NewQr').addClass('is-invalid')
                    $('#infoQR').html(res.data.message)
                    $('#state').val(1)
                }
            })
            .catch((error)=>{
                console.log('Error => '+error)
            })
        }else{

        }
    }

    async function RegistrarCristal(e){
        e.preventDefault()
        if($('#state').val()==0){
            const Datos ={
                QR : $('#NewQr').val(),
                Sph : $('#NewSph').val(),
                Cyl : $('#NewCyl').val(),
                Material : $('#NewMaterial').val(),
                Rango : $('#NewRango').val()
            }
            await axios.post(backend+'/api/inventarioCristales/new/cristal',{'NuevoCristal':Datos},{headers : { authorization :'Bearere '+Token}})
            .then(res=>{
                if(res.data.code===1){
                    $('#TablaCristales').DataTable().ajax.reload( null, false );
                    CerrarModal1(1)
                    limpiar1(1)
                    toast.success('Cristal Creado',{
                        autoClose: 2000,
                        toastId:customId2,
                        pauseOnHover: false,
                    });
                }else{
                    toast.error('No fue posible Crear el Cristal',{
                        autoClose: 2000,
                        toastId:customId2,
                        pauseOnHover: false,
                    });
                    CerrarModal1(1)
                }
            })
            .catch((error)=>{
                console.log('Error => '+error)
            })
            console.log(Datos)
        }else{
            //error toast
        }
    }
    function limpiar1(e){
        $('#NewQr').val('')
        $('#NewQr').addClass('is-invalid')
        $('#NewSph').val('')
        $('#NewSph').addClass('is-invalid')
        $('#NewCyl').val('')
        $('#NewCyl').addClass('is-invalid')
        $('#NewMaterial').val('')
        $('#NewMaterial').addClass('is-invalid')
        $('#NewRango').val('')
        $('#NewRango').addClass('is-invalid')
        $('#state').val(0)
        $('#infoQR').addClass('d-none')
        $('#infoQR').html('')
    }
    async function ModificarStock(e){
        e.preventDefault()
        const datos = {
            id : $('#ModStockID').val(),
            Sph : $('#ModStockSph').val(),
            Cyl : $('#ModStockCyl').val(),
            Material : $('#ModStockMaterial').val(),
            Rango : $('#ModStockRango').val(),
            Cantidad : $('#ModStockCantidad').val()
        }
        await axios.post(backend+'/api/inventarioCristales/find/exp/update',{'DatosModificar':datos},{headers : { authorization :'Bearere '+Token}})
        .then(res=>{
            if(res.data.code===1){
                $('#TablaCristales').DataTable().ajax.reload( null, false );
                toast.success('Stock Modificado ....',{
                    autoClose: 2000,
                    toastId:customId2,
                    pauseOnHover: false,
                });
                CerrarModal2(1)
                //toast success
                toast.error('Error Al Modificar Stock',{
                    autoClose: 2000,
                    toastId:customId2,
                    pauseOnHover: false,
                });
                CerrarModal2(1)
            }else{
                //toast error
            }
        })
        .catch((error)=>{
            console.log('Error => '+error)
        })
        
    }
    async function Eliminar(e){
       axios.post(backend+'/api/inventarioCristales/delete/id',{'_id':e},{headers : { authorization :'Bearere '+Token}})
       .then(res=>{
            if(res.data.code===1){
                $('#TablaCristales').DataTable().ajax.reload( null, false );
                CerrarModal2(1)
                toast.success('Cristal Eliminado',{
                    autoClose: 2000,
                    toastId:customId2,
                    pauseOnHover: false,
                });
            }else{
                $('#TablaCristales').DataTable().ajax.reload( null, false );
                CerrarModal2(1)
                toast.error('Cristal no pudo ser Eliminado',{
                    autoClose: 2000,
                    toastId:customId2,
                    pauseOnHover: false,
                });
            }
       })
       .catch(error=>{
            console.log('Error => '+error)
       })
    } 
















    return(
        <main className="content-wrapper">
            <div className="p-4">
                <div className="row">
                    <h3>Inventario Cristales</h3>
                </div>
                <ToastContainer />
                <table className="table table-sm" id="TablaCristales">
                    <thead>
                        <tr>
                            <th><label>#</label></th>
                            <th><label>QR</label></th>
                            <th><label>Cristal</label></th>
                            <th><label>Sph</label></th>
                            <th><label>Cyl</label></th>
                            <th><label>Material</label></th>
                            <th><label>Rango</label></th>
                            <th><label>Cantidad</label></th>
                        </tr>
                    </thead>
                </table>
            </div>
            <Modal size="lg" show={AbrirModal1} onHide={CerrarModal1} aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">Ingreso Nuevo Cristal</Modal.Title>
                </Modal.Header>
                <form className="form-horizontal" onSubmit={e=>RegistrarCristal(e)}>
                    <Modal.Body>
                        <div className="form-group">
                            <label htmlFor="title" className="col-sm-4 control-label">Codigo de Barra</label>
                            <div className="col-sm-12">
                                <input type="text" name="NewQr" onFocus={e=>EsconderQR(e)} onBlur={e=>existQR(e)} required className="form-control is-invalid" id="NewQr" placeholder="Codigo Qr"/>
                            </div>
                            <label htmlFor="title" id="infoQR" className="col-sm-12 control-label d-none text-danger"></label>
                            <input type='hidden' id="state" name="state" defaultValue={0}/>
                            </div>
                        <div className="form-group">
                            <label htmlFor="title" className="col-sm-4 control-label">Sph</label>
                            <div className="col-sm-12">
                                <input type="number" step={0.25} name="NewSph" onChange={e=>isValid(e)} required className="form-control is-invalid" id="NewSph" placeholder="Sph"/>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="title" className="col-sm-4 control-label">Cyl</label>
                            <div className="col-sm-12">
                                <input type="number" step={0.25} name="NewCyl" onChange={e=>isValid(e)} required className="form-control is-invalid" id="NewCyl" placeholder="Cyl"/>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="title" className="col-sm-4 control-label">Material</label>
                            <div className="col-sm-12">
                                <input type="text" name="NewMaterial" onChange={e=>isValid(e)} required className="form-control is-invalid" id="NewMaterial" placeholder="Mat"/>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="title" className="col-sm-4 control-label">Rango</label>
                            <div className="col-sm-12">
                                <input type="text" className="form-control is-invalid" onChange={e=>isValid(e)} id="NewRango" name="NewRango" required />
                            </div>
                        </div>    
                   
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={e=>CerrarModal1(e)}>Cerrar</Button>
                    <Button variant="primary" type="submit">Crear Cristal</Button>
                </Modal.Footer>
                </form>
            </Modal>
            <Modal size="lg" show={AbrirModal2} onHide={CerrarModal2} aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">Modo Administrador (Modificar Stock)</Modal.Title>
                </Modal.Header>
                <form className="form-horizontal" onSubmit={e=>ModificarStock(e)}>
                <Modal.Body>
                    
                        <div className="form-group">
                            <label htmlFor="title" className="col-sm-4 control-label">Codigo de Barra</label>
                            <div className="col-sm-12">
                                <input type="text" name="ModStockQr" readOnly={true} required className="form-control is-valid" id="ModStockQr" placeholder="Codigo Qr"/>
                            </div>
                            <input type='hidden' id="ModStockID" name="ModStockID" defaultValue={0}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="title" className="col-sm-4 control-label">Sph</label>
                            <div className="col-sm-12">
                                <input type="number" step={0.25} name="ModStockSph" readOnly required className="form-control is-valid" id="ModStockSph" placeholder="Sph"/>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="title" className="col-sm-4 control-label">Cyl</label>
                            <div className="col-sm-12">
                                <input type="number" step={0.25} name="ModStockCyl" readOnly required className="form-control is-valid" id="ModStockCyl" placeholder="Cyl"/>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="title" className="col-sm-4 control-label">Material</label>
                            <div className="col-sm-12">
                                <input type="text" name="ModStockMaterial" required readOnly className="form-control is-valid" id="ModStockMaterial" placeholder="Mat"/>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="title" className="col-sm-4 control-label">Rango</label>
                            <div className="col-sm-12">
                                <input type="text" className="form-control is-valid"  id="ModStockRango" name="ModStockRango" required />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="title" className="col-sm-4 control-label">Cantidad</label>
                            <div className="col-sm-12">
                                <input type="number" min={0} className="form-control is-valid" id="ModStockCantidad" name="ModStockCantidad" required />
                            </div>
                         </div>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={e=>Eliminar($('#ModStockID').val())}>Eliminar</Button>
                    <Button variant="secondary" onClick={e=>CerrarModal2(e)}>Cerrar</Button>
                    <Button variant="primary" type="submit">Guardar Cambios</Button>
                </Modal.Footer>
                </form>
            </Modal>

        </main>
    )
}

export default View