import {useState,useEffect} from 'react'
import $ from 'jquery'
import io from "socket.io-client";
import axios from 'axios'
import FullCalendar from '@fullcalendar/react'
import '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from '@fullcalendar/bootstrap';
import listDay from '@fullcalendar/list'
import listWeek from '@fullcalendar/list'
import listYear from '@fullcalendar/list' 
import list from '@fullcalendar/list'
import 'animate.css'
import { Button, Modal, Alert } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
//import '../../Script/Aside'

const customId = "custom-id-yes";
const customId2 = "custom-id-yes2";

const Calendario = () =>{
    const Token = sessionStorage.getItem('Token')
    const backend = process.env.REACT_APP_BACKEND_URL2
    const socket = io.connect(backend)
    const [citaciones,setCitaciones]=useState([])
    const [dateStartCalendar,setDateStartCalendar]=useState(null)
    const [dateEndCalendar,setDateEndCalendar]=useState(null)
    const [dateAllDAy,setDateAllDay]=useState(false)
    const [locales,setLocales]=useState([])
    const [showNewEvent, setShowNewEvent] = useState(false);
    const [showEditEvent, setShowEditEvent] = useState(false);
    const [reloadEvent,setReloadEvent]=useState(false)
    const [Accout,setAccout] = useState(JSON.parse(sessionStorage.getItem('User')))
    const [datosModificar,setDatosModificar]=useState([])
    const [news,setNew]= useState([])
    const Recargar = ()=>setReloadEvent(true)
    const NoRecargar = () =>setReloadEvent(false)
    const RecargarNoticias = () =>setUpdateNew(true)
    const NorecargarNoticias = ()=>setUpdateNew(false)
    const handleClose1 = () => setShowNewEvent(false);
    const handleClose2 = () => setShowEditEvent(false);
    const [UpdateNew,setUpdateNew]=useState(false)
    const [datosInsertNoticia,setDatosInsertNoticia]=useState({
        TituloNoticia : '',
        DetalleNoticia : '',
        Estado: 1
    })
    
    



    useEffect(() => {
        cargarCalendario(1);
        CargaLocales(1)
        CargarNoticias(1)
        console.log(Accout)
    },[])

    useEffect(() => {
        socket.on('Nuevo Evento Creado',()=>{
            toast.success('Nuevo Evento Encontrado. Actualizando Calendario ....',{
                autoClose: 2000,
                toastId: customId
            });
        })
        
        NoRecargar() 
    })
    useEffect(() => {
        switch(datosModificar.tipoAtencion){
            case "RX":
                $('#ModRX').prop('checked','checked')
            break
            case "LC":
                $('#ModLC').prop('checked','checked')
            break
            case "Protesis":
                $('#ModProtesis').prop('checked','checked')
            break
            default :
            break
        }
    })
    useEffect(()=>{
        cargarCalendario(1);
    },[reloadEvent===true])
    useEffect(() => {
        CargarNoticias(1)
        
    },[UpdateNew===true])
    const handleShow1 = () => setShowNewEvent(true);
    const handleShow2 = () => setShowEditEvent(true);
    async function ComprobarEliminarNoticia(e){
        await axios.post(backend+'/api/calendario/noticias/del',{'_id':e},{headers : { authorization :'Bearere '+Token}})
        .then(res=>{
            //console.log(res.data.code)
            toast.success('Noticia Eliminada ....',{
                autoClose: 2000,
                pauseOnHover: false,
            });
            CargarNoticias(1)
        })
        .catch((error)=>{
            console.log('Error => '+error)
        })
        
    }
    async function CargaLocales(e){
        await axios.post(backend+'/api/sucursales/find/all',{},{headers : { authorization :'Bearere '+Token}})
        .then(res=>{
            if(res.data.code ===1){
                setLocales(res.data.datos)
            }
        })
        .catch((error)=>{
            console.log('Error => '+error)
        })
    }
    function RangoSeleccionado(e){
        const Inicio = e.startStr
        const Fin = e.endStr
        //console.log(Inicio)
        //console.log(Fin)
        if(Inicio.length===10){
            const allDay=e.allDay
            setDateAllDay(true)
            setDateStartCalendar('')
            setDateEndCalendar('')
            handleShow1()
            //console.log(allDay)
            //console.log(view)
           
        }else{
            //console.log(e)
            setDateStartCalendar(Inicio)
            setDateEndCalendar(Fin)
            setDateAllDay(false)
            handleShow1()
        }
        
    }
    async function ModificarEvento(e){
        //console.log(e)
        const DatosModificar = {
            start : e.event.startStr,
            end : e.event.endStr,
            _id : e.event.extendedProps._id
        }
        //console.log(DatosModificar)
        await axios.post(backend+'/api/calendario/calendarioCitaciones/modify',{'DatosModificar':DatosModificar},{headers : { authorization :'Bearere '+Token}})
        .then(res=>{
            //console.log(res.data.message)
            socket.emit("Cargando Eventos",res.data.message)
        })
        .catch((error)=>{
            console.log('Error => '+error)
        })
        Recargar()
        
    }
    async function ModificarDatosEvento(e){

        setDatosModificar(e.event.extendedProps)

        
        handleShow2(e)
    }
    async function RegistrarCitacion(e){
        console.log('Registrando')
        let TipoAtencion =0
        if($('#RX').prop('checked')){
            TipoAtencion = 'RX'
        }
        if($('#LC').prop('checked')){
            TipoAtencion = 'LC'
        }
        if($('#Protesis').prop('checked')){
            TipoAtencion = 'Protesis'
        }
        let allDay=false
        if($('#NewAllDay').prop('checked')){
            allDay = true
        }
        const DatosCitacion ={
            rut : document.getElementById('NewRut').value,
            nombre : document.getElementById('NewNombre').value,
            fono : document.getElementById('NewTelefono').value,
            email : document.getElementById('NewCorreo').value,
            color : document.getElementById('NewSucursal').value,
            tipoAtencion : TipoAtencion,
            start : document.getElementById('NewStart').value,
            end : document.getElementById('NewEnd').value,
            allDay : allDay,
            backgroundColor : document.getElementById('NewSucursal').value,
            title : TipoAtencion+' - '+document.getElementById('NewNombre').value,
            descripcion : TipoAtencion+' - '+document.getElementById('NewNombre').value
        }
        //console.log(DatosCitacion)
        await axios.post(backend+'/api/calendario/calendarioCitaciones/new',{'DatosCitacion':DatosCitacion},{headers : { authorization :'Bearere '+Token}})
        .then(res=>{
            //console.log(res.data.message)
            socket.emit("Cargando Eventos",res.data.message)
        })  
        .catch((error)=>{
            console.log('Error => '+error)
        })
        handleClose1()
        Recargar()
    }
    async function cargarCalendario(e){
        await axios.post(backend+'/api/calendario/calendarioCitaciones/find/all',{},{headers : { authorization :'Bearere '+Token}})
        .then(res=>{
            setCitaciones(res.data)
            toast.success('Cargando Eventos ....',{
                autoClose: 2000,
                toastId:customId2,
                pauseOnHover: false,
            });
        })
        .catch((error)=>{
            console.log('Error => '+error)
        })
        NoRecargar()
    }
    async function EliminarEvento(e){
        await axios.post(backend+'/api/calendario/calendarioCitaciones/delete/event',{'_id':e},{headers : { authorization :'Bearere '+Token}})
        .then(res=>{
            socket.emit("Cargando Eventos",res.data.message)
        })
        .catch((error)=>{
            console.log('Error => '+error)
        })
        toast.success('Evento Eliminado ....',{
            autoClose: 2000,
            toastId:customId2,
            pauseOnHover: false,
        });
        handleClose2()
        cargarCalendario(1);
        RecargarNoticias()
    }
    async function CargarNoticias(e){
        await axios.post(backend+'/api/calendario/noticias/all',{},{headers : { authorization :'Bearere '+Token}})
        .then(res=>{
            setNew(res.data.datos)
            if(res.data.code===1){
                toast.success('Cargando Noticias ....',{
                    autoClose: 2000,
                    pauseOnHover: false,
                });
                
            }else{

            }
        })
        .catch((error)=>{
            console.log('Error => '+error)
        })
        NorecargarNoticias(1)
    }
    async function PushearNoticia(e){
        
        let Titulo = $('#TituloNoticia').val()
        let Detalle = $('#DetalleNoticia').val()
        if(Titulo.length>=1 && Detalle.length>=1){
            //console.log('Puedo Pushear')
            let Datos = {
                Titulo : Titulo,
                Detalle : Detalle,
                CreadoPor : Accout.Username
            }
            await axios.post(backend+'/api/calendario/noticias/push',{'Datos':Datos},{headers : { authorization :'Bearere '+Token}})
            .then(res=>{
                if(res.data.code ===1){
                    toast.error('Noticia Creada ....',{
                        autoClose: 2000,
                        pauseOnHover: false,
                    });
                    RecargarNoticias(1)
                }
                
            })
            .catch((error)=>{
                console.log('Error => '+error)
            })


        }else{
            toast.error('Imposible Crear Noticia ....',{
                autoClose: 2000,
                pauseOnHover: false,
            });
        }
    }
    async function ModificarCitacion(e){
        e.preventDefault()
        let id = $('#ModId').val()
        let Rut = $('#ModRut').val()
        let Nombre = $('#ModNombre').val()
        let Telefono = $('#ModTelefono').val()
        let Sucursal = $('#ModSucursal').val()
        let Correo = $('#ModCorreo').val()
        let TipoAtencion=0
        if($('#ModRX').prop('checked')){
            TipoAtencion = 'RX'
        }
        if($('#ModLC').prop('checked')){
            TipoAtencion = 'LC'
        }
        if($('#ModProtesis').prop('checked')){
            TipoAtencion = 'Protesis'
        }
        let datosSend={
            id : id,
            rut : Rut,
            nombre : Nombre,
            fono : Telefono,
            color : Sucursal,
            email : Correo,
            tipoAtencion : TipoAtencion,
            title : TipoAtencion+' - '+Nombre
        }
        await axios.post(backend+'/api/calendario/calendarioCitaciones/datosPacientes/modify',{'Datos':datosSend},{headers : { authorization :'Bearere '+Token}})
        .then(res=>{
            if(res.data.code===1){
                handleClose2()
                Recargar()
                //console.log(res.data)
                socket.emit("Cargando Eventos",res.data.message)
            }else{

            }
            
        })
        .catch((error)=>{
            console.log('Error => '+error)
        })
        //console.log(datosSend)
    }

    return (

        <main className="content-wrapper">
                <div className="p-4">
                    <div className="card card-success card-outline">
                        <div className="card-header">
                            <h3 className="card-title">Sucursales Disponibles</h3>
                            <div className="card-tools">
                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                    <i className="fas fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <ul className="list-group list-group-horizontal">
                                {locales.map(item=>
                                    <li key={item._id} className="list-group-item text-center">
                                        {item.Nombre} <br/> {item.Direccion} <br/> <input type="color" readOnly={true} className="form-control form-control-color" value={item.Color}/>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div> 
                    <br/>
                    <div className="card card-info card-outline">
                        <div className="card-header">
                            <h3 className="card-title">Panel De Noticias</h3>
                            <div className="card-tools">
                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                    <i className="fas fa-minus"></i>
                                </button>
                        </div>
                    </div>
                    <div className="card-body">
                        {news.length >= 1 ? 
                            news.map(items=>(
                                (<Alert key={items._id} variant='success'>
                                    <Alert.Heading>{items.Titulo}!</Alert.Heading>
                                    <p>
                                        {items.Detalle}
                                    </p>
                                    {Accout.Rol === 'Desarrollador' ?
                                        (
                                            <div className="d-flex justify-content-end">
                                        <Button className='btn btn-danger' onClick={() => ComprobarEliminarNoticia(items._id)} >
                                            Eliminar Noticia
                                        </Button>
                                        </div>
                                        )
                                        :(<p></p>)
                                    }
                                    {Accout.Rol === 'Administrador' ?
                                        (
                                            <div className="d-flex justify-content-end">
                                        <Button className='btn btn-danger' onClick={() => ComprobarEliminarNoticia(items._id)} >
                                            Eliminar Noticia
                                        </Button>
                                        </div>
                                        )
                                        :(<p></p>)
                                    }
                                    
                                </Alert>)
                            ))
                            :
                            (<Alert variant='danger'> No Hay Noticias Nuevas ......!</Alert>)
            
                        }
                    </div>
                    <div className="card-footer">
                    {Accout.Rol === 'Desarrollador'  ?
                        (
                            <div className="form-row">
                                <div className="form-group col-md-3">
                                    <label htmlFor="Titulo">Titulo</label>
                                    <input type="text" className="form-control" id="TituloNoticia" name='TituloNoticia' placeholder="Titulo Noticia ..."/>
                                </div>
                                <div className="form-group col-md-8">
                                    <label htmlFor="Detalle">Detalle</label>
                                    <input type="text" className="form-control" id="DetalleNoticia" name='DetalleNoticia' placeholder="Detalle......"/>
                                </div>
                                <div className="form-group col-md-1">
                                    <br/>
                                    <button type="button" onClick={e=>PushearNoticia(e)} className="btn btn-primary" id="btnPushNoticia">Push Noticia</button>
                                </div>
                                
                            </div> 
    
                            )
                     : (<p></p>)
                        
                
                }
                {Accout.Rol === 'Administrador'  ?
                        (
                            <div className="form-row">
                                <div className="form-group col-md-3">
                                    <label htmlFor="Titulo">Titulo</label>
                                    <input type="text" className="form-control" id="TituloNoticia" name='TituloNoticia' placeholder="Titulo Noticia ..."/>
                                </div>
                                <div className="form-group col-md-8">
                                    <label htmlFor="Detalle">Detalle</label>
                                    <input type="text" className="form-control" id="DetalleNoticia" name='DetalleNoticia' placeholder="Detalle......"/>
                                </div>
                                <div className="form-group col-md-1">
                                    <br/>
                                    <button type="button" onClick={e=>PushearNoticia(e)} className="btn btn-primary" id="btnPushNoticia">Push Noticia</button>
                                </div>
                                
                            </div> 
    
                            )
                     : (<p></p>)
                        
                
                }
                    </div>
                </div>

                
                <ToastContainer />
                <FullCalendar
                    plugins={[bootstrapPlugin, timeGridPlugin , dayGridPlugin ,list , listDay, listYear,  listWeek , interactionPlugin ]}
                    initialView="timeGridWeek"
                    headerToolbar={{ left : 'title',
                        right : 'prevYear prev today next nextYear timeGridDay timeGridWeek dayGridMonth listWeek'
                    }}
                    
                    titleFormat={{day: 'numeric' ,  month: 'long',year: 'numeric' 
                    }}
                    handleWindowResize = 'true'
                    locale={'esLocale'}
                    firstDay={'1'}
                    nowIndicator={'true'}
                    buttonText={{
                        today : 'Hoy',
                        month : 'Mes',
                        list : 'Lista De Eventos',
                        day : 'Dia',
                        week : 'Semana'
                    }}
                    navLinks={'true'}
                    events={citaciones}
                    themeSystem={'bootstrap'}
                    
                    windowResizeDelay={'100'}
                    weekNumbers={'true'}
                    select={
                        e=>RangoSeleccionado(e)
                    }
                    eventDrop={e=>ModificarEvento(e)
                    }
                    eventResize={e=>ModificarEvento(e)
                    }
                    eventClick={e=>ModificarDatosEvento(e)
                    }
                    
                    slotDuration ='00:05:00'
                    slotMinTime = '09:00:00'
                    slotMaxTime = '18:00:00'
                    
                    selectable='true'
                    
                />
                </div>
                <Modal show={showNewEvent} size="lg" onHide={handleClose1}>
                    <Modal.Header closeButton>
                        <Modal.Title>Nueva Atencion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label htmlFor="title" className="col-sm-4 control-label">Rut Paciente</label>
                            <div className="col-sm-12">
                                <input type="text" className="form-control" name="NewRut" id="NewRut" placeholder="Rut"/>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="title" className="col-sm-4 control-label">Nombre</label>
                            <div className="col-sm-12">
                                <input type="text" name="NewNombre" className="form-control" id="NewNombre" placeholder="Nombre Paciente"/>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="title" className="col-sm-4 control-label">Telefono</label>
                            <div className="col-sm-12">
                                <input type="text" name="NewTelefono" className="form-control" id="NewTelefono" placeholder="Telefono"/>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="title" className="col-sm-4 control-label">Correo</label>
                            <div className="col-sm-12">
                                <input type="text" name="NewCorreo" className="form-control" id="NewCorreo" placeholder="Correo"/>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="title" className="col-sm-4 control-label">Sucursal</label>
                            <div className="col-sm-12">
                                <select className="form-control" id='NewSucursal' name='NewSucursal'>
                                    {locales.map(item=>
                                        <option key={item._id} value={item.Color} style={{backgroundColor:item.Color}}>{item.Nombre}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="title" className="col-sm-4 control-label">Tipo Atencion</label>
                            <div className="col-sm-12">
                                <div className="form-group clearfix">
                                    <div className="icheck-success d-inline">
                                        <input type="radio" name="r3" defaultChecked id="RX"/>
                                        <label htmlFor="RX">RX
                                        </label>
                                    </div>
                                    <div className="icheck-success d-inline">
                                        <input type="radio" name="r3" id="LC"/>
                                        <label htmlFor="LC">Lentes De Contacto
                                        </label>
                                    </div>
                                    <div className="icheck-success d-inline">
                                        <input type="radio" name="r3" id="Protesis"/>
                                        <label htmlFor="Protesis">Protesis
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group d-none" >
                            <label htmlFor="title" className="col-sm-6 control-label">Evento Tipo El Dia</label>
                            <div className="col-sm-12">
                                <input type="checkbox"  id="NewAllDay" name="NewAllDay" defaultChecked={dateAllDAy} />
                                <input type="text" name="NewStart" className="form-control" id="NewStart" defaultValue={dateStartCalendar}/>
                                <input type="text" name="NewEnd" className="form-control" id="NewEnd" defaultValue={dateEndCalendar} />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose1}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={e=>RegistrarCitacion(e)}>
                            Guardar Citacion
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showEditEvent} size="lg" onHide={handleClose2}>
                    <Modal.Header closeButton>
                        <Modal.Title>Modificar Atencion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label htmlFor="title" className="col-sm-4 control-label">Rut Paciente</label>
                            <div className="col-sm-12">
                                <input type="hidden" className="form-control" name="ModId" id="ModId" placeholder="Id" defaultValue={datosModificar._id}/>
                                <input type="text" className="form-control" name="ModRut" id="ModRut" placeholder="Rut" defaultValue={datosModificar.rut}/>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="title" className="col-sm-4 control-label">Nombre</label>
                            <div className="col-sm-12">
                                <input type="text" name="ModNombre" className="form-control" id="ModNombre" placeholder="Nombre Paciente" defaultValue={datosModificar.nombre}/>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="title" className="col-sm-4 control-label">Telefono</label>
                            <div className="col-sm-12">
                                <input type="text" name="ModTelefono" className="form-control" id="ModTelefono" placeholder="Telefono" defaultValue={datosModificar.fono}/>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="title" className="col-sm-4 control-label">Correo</label>
                            <div className="col-sm-12">
                                <input type="text" name="ModCorreo" className="form-control" id="ModCorreo" placeholder="Correo" defaultValue={datosModificar.email}/>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="title" className="col-sm-4 control-label">Sucursal</label>
                            <div className="col-sm-12">
                                <select className="form-control" id='ModSucursal' defaultValue={datosModificar.backgroundColor} name='ModSucursal'>
                                    {locales.map(item=>
                                        <option key={item._id} value={item.Color} style={{backgroundColor:item.Color}}>{item.Nombre}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="title" className="col-sm-4 control-label">Tipo Atencion</label>
                            <div className="col-sm-12">
                                <div className="form-group clearfix">
                                    <div className="icheck-success d-inline">
                                        <input type="radio" name="r3" id="ModRX" />
                                        <label htmlFor="ModRX">RX
                                        </label>
                                    </div>
                                    <div className="icheck-success d-inline">
                                        <input type="radio" name="r3" id="ModLC" />
                                        <label htmlFor="ModLC">Lentes De Contacto
                                        </label>
                                    </div>
                                    <div className="icheck-success d-inline">
                                        <input type="radio" name="r3" id="ModProtesis" />
                                        <label htmlFor="ModProtesis">Protesis
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="form-group d-none">
                            <label htmlFor="title" className="col-sm-6 control-label">Evento Tipo El Dia</label>
                            <div className="col-sm-12">
                                <input type="checkbox"  id="ModAllDay" name="ModAllDay" />
                                <input type="text" name="ModStart" className="form-control" id="ModStart"/>
                                <input type="text" name="ModEnd" className="form-control" id="ModEnd" />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={e=>EliminarEvento(datosModificar._id)}>
                            Eliminar
                        </Button>
                        <Button variant="secondary" onClick={handleClose2}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={e=>ModificarCitacion(e)}>
                            Guardar Citacion
                        </Button>
                    </Modal.Footer>
                </Modal>
                {
                    
                }
                


        </main>
    )
}

export default Calendario